import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CiEdit } from 'react-icons/ci';
import { FiSave } from 'react-icons/fi';
import { IoDocumentLockOutline, IoReturnUpBackOutline } from 'react-icons/io5';
import SignatureCanvas from 'react-signature-canvas';
import Swal from 'sweetalert2';
import useHttpService from '../../hooks/useHttpService';
import * as S from './styles';

const ProfileDocuments = () => {
  const sign = useRef(null);

  const [enableEditorSignature, setEnableEditorSignature] = useState(false);

  const { data: signature, handleRequest } = useHttpService(
    `/user/doctor/signature`,
  );

  const {
    data: responseSignature,
    loading,
    handleRequest: postRequest,
  } = useHttpService('/user/doctor/signature/save');

  useEffect(() => {
    handleRequest('get');
  }, [handleRequest]);

  const toggleEditorSignature = () => {
    setEnableEditorSignature(!enableEditorSignature);
  };

  const handleSaveSignature = () => {
    postRequest('post', {
      signature: sign.current.toDataURL(),
    });
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Salvando assinatura...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    }

    if (!loading) {
      Swal.close();
    }
  }, [loading]);

  useEffect(() => {
    if (responseSignature) {
      Swal.fire({
        icon: 'success',
        title: 'Assinatura salva com sucesso!',
      }).then(() => window.location.reload());
    }
  }, [responseSignature]);

  useEffect(() => {
    if (sign.current) {
      if (!enableEditorSignature) {
        sign.current.off();
      } else {
        sign.current.on();
      }
    }
  }, [sign, enableEditorSignature]);

  const setSignature = useCallback(() => {
    sign.current.clear();

    if (signature) {
      const signatureInfo = signature;

      sign.current.fromDataURL(signatureInfo.signature);
    }
  }, [signature, sign]);

  useEffect(() => {
    setSignature();
  }, [signature, sign]);

  const handleClearSignature = () => {
    sign.current.clear();
  };

  return (
    <>
      <S.Content>
        <S.InlineTitle>
          <S.Title>
            <IoDocumentLockOutline />
            Assinatura
          </S.Title>
          <S.CompanyLogo
            src='/images/certified/safe-id.png'
            alt='SafeWeb Logo'
          />
        </S.InlineTitle>
        <S.Container>
          <S.TitleHeading>
            Antes de tudo, é necessário que você assine o documento abaixo, para
            criarmos a sua assinatura digital.
          </S.TitleHeading>
          <S.SignatureContainer>
            <S.Signature isEnabled={enableEditorSignature}>
              <SignatureCanvas
                ref={sign}
                penColor='#000'
                canvasProps={{
                  width: 400,
                  height: 130,
                  className: 'sigCanvas',
                }}
              />
            </S.Signature>

            {enableEditorSignature && (
              <S.SignatureActions>
                <S.SaveButton
                  onClick={() => {
                    handleSaveSignature();
                    toggleEditorSignature();
                  }}
                >
                  Salvar <FiSave />
                </S.SaveButton>

                <S.ClearButton
                  onClick={handleClearSignature}
                >
                  Limpar
                </S.ClearButton>

                <S.CancelButton
                  onClick={() => {
                    toggleEditorSignature();
                    setSignature();
                  }}
                >
                  Cancelar <IoReturnUpBackOutline />
                </S.CancelButton>
              </S.SignatureActions>
            )}

            {!enableEditorSignature && (
              <S.SignatureActions>
                <S.ButtonEdit onClick={toggleEditorSignature}>
                  <CiEdit />
                  Editar assinatura
                </S.ButtonEdit>
              </S.SignatureActions>
            )}
          </S.SignatureContainer>
        </S.Container>
      </S.Content>
    </>
  );
};

export default ProfileDocuments;
