// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 18px;
`;
export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  margin: 0px 0px 20px 0;
  display: flex;
  gap: 0.4em;
  align-items: center;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Avatar = styled.div`
  // Style for the avatar
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
`;

export const PatientName = styled.h3`
  margin: 0;
  color: #333;
`;

export const PatientStatus = styled.span`
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
`;

export const ConditionList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 0.3em;
  flex-wrap: wrap;
`;

export const ConditionItem = styled.li`
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(
    --BlueNews,
    linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%)
  );
  color: rgb(255, 255, 255);
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  gap: 0.8em;
  padding: 0.5em 0.7em;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    scale: 1.22;
    padding: 1em 1.4em;
  }
`;

export const IndicatorDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) => (props.active ? '#007bff' : '#e0e0e0')};
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
`;

export const BlueContainer = styled.div`
  border-radius: 24px;
  background: var(
    --BlueNews,
    linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%)
  );
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Profile = styled.img`
  width: 44px;
  height: auto;
`;

export const Check = styled.img`
  width: 12px;
  height: auto;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const StatusSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.4em;
`;

export const UserInfo = styled.div`
  font-size: 14px;
  color: #333;
`;

export const Status = styled.div`
  border-radius: 6px;
  color: #2ab0d4;
  border: 1px solid #2ab0d4;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #2ab0d4;
  text-align: center;
  font-family: Helvetica;
  font-size: 7.13px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 6.13px */
  letter-spacing: 0.429px;
  text-transform: uppercase;
`;

export const Name = styled.h1`
  color: #7d7d7d;
  text-align: left;

  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  line-height: 100%; /* 24.443px */
`;

export const CRM = styled.h3`
  color: #7d7d7d;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  text-align: left;
  line-height: 100%; /* 15.041px */
`;

export const ConditionTitle = styled.h3`
  line-height: 100%; /* 15.041px */
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
