import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { routes } from "./routes";
import "react-calendar/dist/Calendar.css";

function App() {
  return (
    <Router>
      <Routes>
        {routes.map((route, index) => {
          const Template = route.template;
          const Page = route.page;
          const title = route.title;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Template isPublic={route.isPublic}>
                  <Helmet title={`Blue Med - ${title}`} />
                  <Page />
                </Template>
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
