import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpService } from '../../services/axios';

let interval = null

function useGetAvailableRoom() {
  const [room, setRoom] = useState(null);
  const navigate = useNavigate();

  const handleGetAvailableRoom = useCallback(async () => {
    const { data } = await httpService.get('/medical-appointment/available/room')
    setRoom(data); 
  }, [])

  const handleStartGet = useCallback(() => {
    interval = setInterval(() => {
      handleGetAvailableRoom()
    }, 10000)
  }, [handleGetAvailableRoom])

  useEffect(() => {
    handleGetAvailableRoom();

    handleStartGet()

    return () => {
      interval && clearInterval(interval)
    }
  }, [navigate, handleStartGet, handleGetAvailableRoom])

  return room;
}

export default useGetAvailableRoom;
