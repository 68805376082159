import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../../../../components/Input';
import * as S from '../../styles';
import { step1Schema } from './schema';

const Step1 = ({ handleNext }) => {
  const methods = useForm({
    resolver: yupResolver(step1Schema),
  });

  const errors = methods.formState.errors;

  const submit = (values) => {
    console.log('values:::', values);
    handleNext('step1', values);
  };

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(submit)}>
        <Input
          icon='user'
          name='name'
          error={errors?.name?.message}
          placeholder='Insira seu nome'
        />

        <Input
          icon='phone'
          name='whatsapp'
          error={errors?.whatsapp?.message}
          placeholder='Insira seu celular'
          mask='(99) 9 9999-9999'
        />
        <Input
          icon='email'
          name='email'
          error={errors?.email?.message}
          placeholder='Insira seu email'
        />
        <Input
          icon='lock'
          name='password'
          error={errors?.password?.message}
          placeholder='Insira sua senha'
          type='password'
        />

        <Input
          icon='lock'
          name='confirmPassword'
          error={errors?.confirmPassword?.message}
          placeholder='Insira sua senha novamente'
          type='password'
        />

        <S.SendButton>Próximo</S.SendButton>
      </S.Form>
    </FormProvider>
  );
};

export default Step1;
