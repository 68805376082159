import { differenceInMinutes } from 'date-fns';

// Função para salvar um objeto no local storage
export function saveToLocalStorage(key, obj) {
    try {
        const serializedObj = JSON.stringify({ data: obj, $$storage_date_time_cached$$: new Date() });
        localStorage.setItem("@bluemed-dash:" + key, serializedObj);
        console.log(`Objeto salvo com sucesso na chave: ${key}`);
    } catch (e) {
        console.error('Erro ao salvar o objeto no local storage:', e);
    }
}

// Função para consultar (recuperar) um objeto do local storage
export function getFromLocalStorage(key, cacheTime = 5) {
    try {
        const serializedObj = localStorage.getItem("@bluemed-dash:" + key);
        if (serializedObj === null) {
            console.warn(`Nenhum objeto encontrado para a chave: ${key}`);
            return null;
        }
        const obj = JSON.parse(serializedObj);

        if(checkIfIsValid(obj.$$storage_date_time_cached$$) >= cacheTime){
            return null;
        }

        return obj.data;
    } catch (e) {
        console.error('Erro ao recuperar o objeto do local storage:', e);
        return null;
    }
}

function checkIfIsValid(dtStoraged) {
    return differenceInMinutes(new Date(), new Date(dtStoraged))
}

