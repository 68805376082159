export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_MIDDLEWARE = process.env.REACT_APP_API_URL_MIDDLEWARE
export const VIACEP = (cep) => `https://viacep.com.br/ws/${cep.replace('-','')}/json/`
export const USER_INFO_KEY = 'userInfo'
export const STRIPE_PUBLIC_KEY = 'pk_test_51NwCvVG6OWP00bqDJ6S9MIOEhm93IMjzVnhq9qzpMJ6CaKXPO5AY6VbmGJjhld5GrtAqTeFWQJsFRi6BVW9NL20200dFonK3UT'


export const TERMS = {
  TERM_OF_VERACITY: 'TERM_OF_VERACITY',
  TERM_OF_RESPONSABILITY: 'TERM_OF_RESPONSABILITY'
}

export const TERMS_URL = {
  'term-of-veracity': 'term-of-veracity',
  'term-of-responsibility': 'term-of-responsibility'
}

export const URL_TO_TERM = {
  [TERMS_URL["term-of-veracity"]]: TERMS.TERM_OF_VERACITY,
  [TERMS_URL["term-of-responsibility"]]: TERMS.TERM_OF_RESPONSABILITY
}

export const TERM_TO_URL = {
  [TERMS.TERM_OF_VERACITY]: 'term-of-veracity',
  [TERMS.TERM_OF_RESPONSABILITY]: 'term-of-responsibility'
}

export const SAFEWEB_CLIENT_ID = 'blue-med-ikarqxkc'
export const SAFEWEB_CLIENT_SECRET = 'Gp1EC1qkBn1uKgtFoO4vCrAPhEYmPJYED5iR646mu2dD36fvc20Y7ZjWygfyJzvC06rIcjIofAdGrbIQRHBobQLXJ42yOLJjDT583z296uTGFTcR2A3Nxepvvluma7JN'
export const SAFEWEB_API_URL = 'https://pscsafeweb.safewebpss.com.br/Service/Microservice'
export const SAFEWEB_CODE_CHALLENGE = 'E9Melhoa2OwvFrEMTJguCHaoeK1t8URWbuGJSstw-cM'

export const SAFEWEB_LIFETIME = 604800