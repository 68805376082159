import React from 'react';
import * as S from './styles';

const Check = '/assets/icons/check.svg';
const Triagem = '/assets/icons/screening.svg';

const Screening = ({ screenings, multiScreenings }) => {
  if (multiScreenings?.length) {
    return (
      <S.Container>
        <S.Title>
          <S.Icon src={Triagem} alt='Triagem' />
          Triagem
        </S.Title>

        {multiScreenings.map((screening, index) => (
          <S.ConditionList key={`screening-${screening.createdAt}-${index}`}>
            <S.ConditionTitle>
              Realizada em: {screening.createdAt}
            </S.ConditionTitle>

            {screening.screenings?.map((screen, index) => (
              <S.ConditionItem key={`screen-${screen}-${index}`}>
                <S.Check src={Check} alt='Check' />
                {screen}
              </S.ConditionItem>
            ))}
          </S.ConditionList>
        ))}
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.Title>
        <S.Icon src={Triagem} alt='Triagem' />
        Triagem
      </S.Title>

      <S.ConditionList>
        {screenings?.map((screening, index) => (
          <S.ConditionItem key={`screening-${screening}-${index}`}>
            <S.Check src={Check} alt='Check' />
            {screening}
          </S.ConditionItem>
        ))}
      </S.ConditionList>
    </S.Container>
  );
};

export default Screening;
