import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TOKEN_NAME } from '../../env';
import useHttpService from '../../hooks/useHttpService';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import * as S from './styles';

const logo = '/assets/logo.svg';

function MedicalStaff() {
  const navigate = useNavigate();

  const { data, handleRequest, loading } = useHttpService(
    '/users/simple-create',
  );

  const [isSubmit, setIsSubimit] = useState(false);

  // States for form data
  const [currentStep, setCurrentStep] = useState(1);

  const [fieldValue, setFieldValue] = useState({
    step1: {
      name: '',
      whatsapp: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    step2: {
      cpf: '',
      crm: '',
      rg: '',
      dateOfBirth: '',
    },
    step3: {
      state: '',
      street: '',
      city: '',
      neighbourhood: '',
      number: '',
      cep: '',
    },
  });

  const handleNext = (step, data) => {
    setCurrentStep(currentStep + 1);
    setFieldValue((value) => ({
      ...value,
      [step]: data,
    }));

    if (step === 'step3') setIsSubimit(true);
  };

  const handleSubmit = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Aguarde',
        text: 'Estamos cadastrando seu usuário',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      const payload = {
        ...fieldValue.step1,
        ...fieldValue.step2,
        ...fieldValue.step3,
        role: 5, // TODO: arrumar role
      };

      const response = await handleRequest('post', payload);
      localStorage.setItem(TOKEN_NAME, response.jwt);

      if (response?.user?.id) {
        Swal.fire({
          title: 'Usuário cadastrado',
          text: 'Deseja cadastrar outro usuário?',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Fazer login',
        }).then(() => {
          navigate('/');
        });
      }
      console.log(payload);
    } catch (error) {
      await Swal.fire({
        title: 'Erro ao cadastrar',
        text: 'Email ou CPF já cadastrado',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
      }).then((result) => {
        window.location.reload();
      });
    }
  }, [data, loading, navigate, fieldValue]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
    }
  }, [isSubmit]);

  return (
    <S.Body>
      <S.Header>
        <S.Logo src={logo} alt='Blue Med logo' />
        <S.HeadingBox>
          <S.Title>Faça parte do corpo médico</S.Title>
          <S.SubTitle>Cadastre suas informações</S.SubTitle>
        </S.HeadingBox>
      </S.Header>

      <S.Forms>
        {currentStep === 1 && <Step1 handleNext={handleNext} />}
        {currentStep === 2 && <Step2 handleNext={handleNext} />}
        {currentStep === 3 && <Step3 handleNext={handleNext} />}
      </S.Forms>
    </S.Body>
  );
}

export default MedicalStaff;
