import React, { createContext, useState } from "react";
import { useContext } from "react";

export const PrescriptionContext = createContext();

export const usePrescription = () => {
  const context = useContext(PrescriptionContext);
  return context;
};

export const PrescriptionProvider = ({ children }) => {
  const [hasPrescripted, setHasPrescripted] = useState(false);
  const [hasStartedMeet, setHasStartedMeet] = useState(false);
  
  return (
    <PrescriptionContext.Provider
      value={{
        hasPrescripted,
        setHasPrescripted,
        hasStartedMeet,
        setHasStartedMeet,
      }}
    >
      {children}
    </PrescriptionContext.Provider>
  );
};
