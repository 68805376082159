import { object, string } from 'yup';

export const step3Schema = object({
  state: string().required('O estado é obrigatório'),
  street: string().required('O endereço é obrigatório'),
  city: string().required('A cidade é obrigatorio'),
  neighborhood: string().required('O bairro é obrigatório'),
  number: string().required('O numero é obrigatório'),
  cep: string().required('O CEP é obrigatório'),
});
