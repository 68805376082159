import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { httpService } from "../../services/axios";
import { saveToLocalStorage, getFromLocalStorage } from "../../utils/localStorage";

function useMe() {
  const [me, setMe] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const handleGetMeInfo = async () => {
    try {
      const cachedData = getFromLocalStorage('user-me');

      if(!cachedData) {
        setLoading(true);
        const { data } = await httpService.get("/users/profile");
        saveToLocalStorage('user-me', data);
        setMe(data);
      } else {
        setMe(cachedData);
      }
    } catch (error) {
      console.log("Erro ");
    } finally {
      setLoading(false);
    }
  }; 

  useEffect(() => {
    handleGetMeInfo();
  }, [navigate]);

  return { me, loading };
}

export default useMe;
