import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetAvailableRoom from '../../hooks/useGetAvailableRoom';
import useHttpService from '../../hooks/useHttpService';
import * as S from './styles';

import { GrOverview } from 'react-icons/gr';
import { httpService } from '../../services/axios';
import Swal from 'sweetalert2';
const timeZone = 'America/Sao_Paulo';

const Overview = () => {
  const { data, handleRequest } = useHttpService('/dashboard');
  const [roomAvailable, setRoomAvailable] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const room = useGetAvailableRoom();

  const caExists = JSON.parse(localStorage.getItem("@bluemed-dashboard:ca-exists"));

  const handleShowRoomAvailable = () => setRoomAvailable(true);

  useEffect(() => {
    if (room?.id) {
      handleShowRoomAvailable();
    }
  }, [room]);

  useEffect(() => {
    handleRequest('get');
  }, [handleRequest]);

  const hangleNavigate = async (room, appointment) => {
    try {
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos verificando se a consulta foi aceita por outro médico.',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await httpService.get(`/medical-appointment/${appointment}/handshake`);

      if (!data?.accepted) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'A consulta foi aceita por outro médico!',
        });
      }

      await Swal.fire({
        icon: 'success',
        title: 'Consulta aceita!',
        text: 'Você será redirecionado para a sala de espera.',
        timer: 2000,
        showConfirmButton: true,
        timerProgressBar: true,
      });

      navigate(`/room/${room}/schedule/${appointment}`);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado. Tente novamente mais tarde.',
      });
    }
  };

  return (
    <>
      <S.GeneralContainer>
        <S.Title>
          <GrOverview />
          Visão Geral
        </S.Title>
        <S.Container>
          <S.SummaryCard>
            <S.CardInfos>
              <S.CardTitle>Pacientes</S.CardTitle>
              <S.CardCount>{data?.uniqueCustomers}</S.CardCount>
            </S.CardInfos>
            <S.IconWrapper
              style={{
                background:
                  'linear-gradient(180deg, rgba(45, 200, 168, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)',
              }}
            >
              <S.Icon src='/images/account/users.svg' alt='Patients' />
            </S.IconWrapper>
          </S.SummaryCard>

          <S.SummaryCard>
            <S.CardInfos>
              <S.CardTitle>Agendamentos</S.CardTitle>
              <S.CardCount>{data?.schedules}</S.CardCount>
            </S.CardInfos>
            <S.IconWrapper
              style={{
                background:
                  'linear-gradient(180deg, rgba(254, 128, 132, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)',
              }}
            >
              <S.Icon
                src='/images/account/appointments.svg'
                alt='Appointments'
              />
            </S.IconWrapper>
          </S.SummaryCard>
          {(room?.id && caExists) && (
            <S.NextConsultationCard
              onClick={() =>
                hangleNavigate(
                  room?.attributes?.room,
                  room?.id,
                )
              }
            >
              <S.CardTitleBlue>
                Próxima Consulta{' '}
                <S.SvgIcon src='/images/account/next.svg' alt='Rachel Greene' />{' '}
              </S.CardTitleBlue>

              <S.Infos>
                <S.UserInfo>
                  <S.PatientName>
                    {room?.attributes?.customer?.data?.attributes?.name}
                  </S.PatientName>
                  <S.ConsultationType>Chamada de Vídeo</S.ConsultationType>
                </S.UserInfo>
              </S.Infos>
            </S.NextConsultationCard>
          )}
        </S.Container>
      </S.GeneralContainer>
    </>
  );
};

export default Overview;
