import { default as React } from 'react';

import AdminBar from '../../components/AdminBar';
import ProfileCertified from '../../components/ProfileCertified';
import ProfileCover from '../../components/ProfileCover';
import ProfileDocuments from '../../components/ProfileDocuments';
import ProfileSettings from '../../components/ProfileSettings';

import useMe from '../../hooks/useMe';
import * as S from './styles';

const completeAddress = (address) => {
  return `${address?.street}, ${address?.number} - ${address?.neighborhood} - ${address?.state}`;
};

const Home = () => {
  const { me: userInfo } = useMe();

  return (
    <S.Body>
      <AdminBar />
      <S.ContentInline>
        <ProfileCover
          name={userInfo?.name}
          crm={userInfo?.crm}
          status={'ATIVO'}
        />

        {!!userInfo?.id && (
          <ProfileSettings
            {...userInfo}
            person={{ name: userInfo?.name }}
            address={completeAddress(userInfo?.address)}
            zipcode={userInfo?.address?.cep}
            city={userInfo?.address?.city}
            phone={userInfo?.whatsapp}
          />
        )}
      </S.ContentInline>
      <S.ColumnAppointment>
        <ProfileDocuments />
        <ProfileCertified />
      </S.ColumnAppointment>
    </S.Body>
  );
};

export default Home;
