// styles.ts
import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(
    --BgBlur,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
  backdrop-filter: blur(47px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;

  align-items: center;
  background-color: #f2f2f2;
  border-radius: 12px;
  svg {
    font-size: 24px;
    margin: auto 0px auto 15px;

    color: #667c89;
  }
  overflow: hidden;

  :active {
    background-color: #f2f2f2;
  }
`;

export const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 3em;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 31px;
  background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));
  box-shadow: 0px 15px 15px 0px rgba(147, 174, 186, 0.25);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 34px;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const StepContent = styled.div`
  padding: 20px 0;
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  border: none;
  color: #667c89;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 1.4em;
  font-size: 15px;
  font-style: normal;
  margin-top: 1em;
  font-weight: 700;
  line-height: 100%;
  font-family: 'PF Handbook Pro Regular';
  height: 45px;
  align-items: center;
  flex-shrink: 0;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 5px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #e7ecef;
  margin-bottom: 1em;
  &:hover {
    background-color: #667c89;
    color: #fff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 24px;
  }
`;

// Add other necessary styles for form elements, patient information, etc.

// SEARCH

// styles.js

export const SearchContainer = styled.div`
  border-radius: 15px;
  background: var(--White, #fff);
  padding: 1em;
  box-shadow: 0px 15px 15px 0px rgba(147, 174, 186, 0.25);
`;

export const SearchBox = styled.div`
  border-radius: 15px;
  background: var(--White, #fff);
  font-family: 'PF Handbook Pro Regular', sans-serif;
  padding: 1.4em;
  box-shadow: 0px 15px 15px 0px rgba(147, 174, 186, 0.25);
`;

export const SearchWrapper = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;

  align-items: center;
  background-color: #f2f2f2;
  border-radius: 12px;
  svg {
    font-size: 24px;
    margin: auto 0px auto 15px;

    color: #667c89;
  }

  overflow: hidden;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  height: 45px;
  line-height: 1em;
  font-size: 16px;
  background-color: transparent;
  &:focus {
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.6);
    border-color: rgba(0, 123, 255, 0.5);
  }
`;

export const Dropdown = styled.select`
  padding: 1em;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
`;

export const TextArea = styled.textarea`
  padding: 1em;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
`;

export const ProductList = styled.div`
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0em;
`;

export const ProductItem = styled.div`
  background-color: #fff;
  padding: 1em;
  font-size: 14px;
  border-radius: 12px;
  margin-bottom: 0.5em;
  transition: all 0.3s ease;
  cursor: pointer;
  background: var(--Grey, linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%));
  transition: all 0.3s ease-in-out;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: #f3f3f3; // Replace with your color
  }
  /* 
  &::before {
    content: '';
    display: inline-block;
    background-image: url('/path/to/default-image.png'); // Replace with your image path
    background-size: cover;
    width: 30px; // Adjust size as needed
    height: 30px; // Adjust size as needed
    margin-right: 10px;
  } */
`;

export const ActiveProductItem = styled(ProductItem)`
  color: #fff;
  &:hover {
  }

  &::after {
    content: '✕'; // "x" icon
    cursor: pointer;
    padding-left: 50px;
  }
`;

export const ModalContent = styled.div`
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PatientInfo = styled.div`
  padding: 1em 0;
  font-size: 21px;
`;

export const PrescriptionSearch = styled.div`
  /* Estilos para a busca de prescrições */
`;

export const UsageDropdown = styled(Dropdown)`
  width: 100%;
  padding: 1em;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  margin-top: 0.5em;
  padding: 1em;
  height: 45px;
  overflow: hidden;
`;

export const ObservationsField = styled.input`
  border-radius: 12px;
  width: 100%;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-family: 'PF Handbook Pro Regular', sans-serif;

  padding: 0.3em 1em 0;
  height: 45px;
  overflow: hidden;

  &:focus {
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.6);
    border-color: rgba(0, 123, 255, 0.5);
  }
`;

export const RecomendationsField = styled(TextArea)`
  border-radius: 12px;
  width: 100%;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-family: 'PF Handbook Pro Regular', sans-serif;

  padding: 1em;
  min-height: 45px;
  overflow: hidden;

  &:focus {
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.6);
    border-color: rgba(0, 123, 255, 0.5);
  }
`;

export const CheckBoxObservations = styled.input`
  cursor: pointer;
  margin-right: 0.5em;
`;

export const LabelObservations = styled.label`
  font-size: 16px;
  font-family: 'PF Handbook Pro Regular', sans-serif;

  height: fit-content;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const ObservationBox = styled.div`
  padding: 1em;
  border-radius: 10px;
  background: var(--White, #fff);
  background-color: #f2f2f2;
  border: 1px dashed #d0d0d0;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  margin-top: 0.5em;
`;

export const Actions = styled.div`
  display: flex;

  gap: 1em;
`;

export const ToggleButton = styled(Button)`
  /* Estilos para o botão de alternância, caso queira estilizar o Button já existente de forma diferente */
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  margin: 0px 0px 20px 0;
  display: flex;
  gap: 0.4em;
  align-items: center;
`;

export const CustomDropdown = styled.div`
  position: relative;
  width: 100%;
  margin: 0.5em 0;
`;

export const DropdownHeader = styled.div`
  padding: 1em;
  background-color: var(--White, #fff);
  border-radius: 12px;
  border: 1px solid #d0d0d0;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '▼';
    font-size: 0.8em;
    padding-left: 1em;
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  background-color: var(--White, #fff);
  border-radius: 12px;
  border: 1px solid #d0d0d0;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  z-index: 10;
  max-height: 200px; // Set a max-height for scrollable list
  overflow-y: auto;
`;

export const DropdownItem = styled.div`
  padding: 1em;
  border-bottom: 1px solid #d0d0d0;
  background-color: var(--White, #fff);
  transition: background-color 0.3s ease;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: var(--LightHover, #f2f2f2);
  }
`;

export const UsageOptions = styled.div`
  display: flex;
  gap: 0.5em;
  margin-top: 0;
`;

export const UsageOption = styled.div`
  padding: 0.4em 1em;
  margin: 0 0 0.5em 0;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8em;
  border: 1px solid #d0d0d0;
  background-color: var(--White, #f2f2f2);
  width: 100%;
  cursor: pointer;
  height: 45px;
  font-size: 15px;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  transition: background-color 0.3s ease;

  background: ${(props) =>
    props.isActive
      ? 'var(--BlueNews, linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%) )'
      : 'var(--ActiveColor, #fff)'};

  color: ${(props) => (props.isActive ? ' #fff' : '#40555E')};
  &:hover {
    background-color: ${(props) =>
    props.isActive
      ? 'var(--ActiveColor, #E0E0E0)'
      : 'var(--LightHover, #f2f2f2)'};
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  label {
    margin-left: 0.5em;
    cursor: pointer;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }
`;

export const ProductPrescriptionArea = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0.8em;
`;

export const ProductPrescriptionCard = styled.div`
  padding: 1em;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
  &:hover {
    transform: translateY(-5px);
  }
  // Additional styling
`;

export const ProductTitle = styled.h3`
  // Title styling
`;

export const ProductConcentration = styled.p`
  // Concentration styling
`;

export const ProductSelectButton = styled.button`
  background: #e7ecef;
  border: none;
  border-radius: 120px;
  font-size: 18px;
  margin-top: 1em;
  width: 100%;
  // Additional styling
  &:hover {
    background-color: #29b5d7;
    color: #fff;
  }
`;

export const RemoveOption = styled.span`
  display: flex;
  align-itens: center;
  cursor: pointer;

  margin-bottom: ${props => (props.firstIcon ? "8px" : "0px")};

  svg {
    font-size: 18px;
  }
`;

export const RemoveLabel = styled.p`
  font-size: 15px;
  margin-right: 1px;
`

// ----------------------------------------------------------

const defaultAnimationValues = {
  duration: 0.2,
  ease: "ease-in-out"
}

export const CollapsibleButton = styled.button`
  padding: 0.6em;

  background: #e7ecef;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 4px 12px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`

const CollapsibleWrapper = styled.div`
  display: grid;
  grid-template-rows: ${props => (props.open ? "1fr" : "0fr")};
  opacity: 0;
  margin-top: 8px;
  transition: all ${props => props.animation.duration}s
    ${props => props.animation.ease};
  opacity: ${props => (props.open ? 1 : 0)};
`;

const CollapsibleInner = styled.div`
  overflow: hidden;
`;

export const Collapsible = ({
  children,
  open,
  animation = defaultAnimationValues
}) => {
  return (
    <CollapsibleWrapper open={open} animation={animation}>
      <CollapsibleInner>{children}</CollapsibleInner>
    </CollapsibleWrapper>
  )
}