import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { httpService } from "../../services/axios";

import WeekSchedule from "./components/WeekSchedule";
import * as S from "./styles";
const AppointmentsIcon = "/assets/icons/agendamentos.svg";

const CreateSchedule = ({ isOpen, closeModal }) => {
  const [schedules, setSchedules] = useState(null);
  const [gettingSchedules, setGettingSchedules] = useState(true);
  const handleGetDoctorSchedules = async () => {
    Swal.fire({
      title: "Carregando...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
    const { data: schedule } = await httpService.get("/doctor-schedules");
    setSchedules(schedule?.attributes);

    setGettingSchedules(false);
    Swal.close();
  };

  useEffect(() => {
    if (isOpen) {
      handleGetDoctorSchedules();
    }
  }, [isOpen]);

  const dias = [
    "segunda",
    "terca",
    "quarta",
    "quinta",
    "sexta",
    "sabado",
    "domingo",
  ];

  const defaultValues = {
    weekdays:
      schedules ||
      dias.reduce((acc, day) => {
        acc[day] = {
          working: false,
          startHour: "",
          intervalHour: "",
          returnHour: "",
          finishHour: "",
        };
        return acc;
      }, {}),
    defaultDuration: "30",
  };

  const methods = useForm({
    defaultValues: schedules || defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (schedules && isOpen) {
      console.log(schedules);
      reset(schedules);
    }
  }, [schedules, reset]);

  const onSubmit = async (data) => {
    try {
      Swal.fire({
        title: "Criando nova agenda...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      const onlyWorkingDays = Object.keys(data.weekdays).reduce((acc, day) => {
        if (data.weekdays[day].working) {
          acc[day] = data.weekdays[day];
        }
        return acc;
      }, {});

      data.weekdays = onlyWorkingDays;

      await httpService.post("/doctor-schedules", data);

      await Swal.fire({
        title: "Agenda criada com sucesso",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      closeModal();
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Erro ao criar agenda",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  };

  if (isOpen && !gettingSchedules)
    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.Modal>
            <S.CardModal>
              <S.ModalHeader>
                <S.Title>
                  <S.Icon src={AppointmentsIcon} alt="teste" />
                  Personalizar agenda e meus horários
                </S.Title>

                <S.DefaultDuration>
                  <S.Label>Duração padrão</S.Label>
                  <S.Select
                    {...methods.register("defaultDuration")}
                    name="defaultDuration"
                    defaultValue={methods.watch("defaultDuration")}
                  >
                    <option value="30">30 minutos</option>
                  </S.Select>
                </S.DefaultDuration>
              </S.ModalHeader>
              <S.WeekdaysContainer>
                {dias.map((day, index) => (
                  <WeekSchedule
                    key={index}
                    day={day}
                    index={index}
                    methods={methods}
                  />
                ))}
              </S.WeekdaysContainer>

              <S.FlexContainer>
                <S.ButtonRetun onClick={closeModal}>
                  <IoCloseOutline />
                  Cancelar
                </S.ButtonRetun>

                <S.Button type="submit">
                  <FiSave />
                  Confirmar
                </S.Button>
              </S.FlexContainer>
            </S.CardModal>
          </S.Modal>
        </form>
      </FormProvider>
    );
};
export default CreateSchedule;
