import styled from 'styled-components';

export const Weekday = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 12px;
  transition: all 0.3s ease;
  color: #333;
  background-color: #fff;
  padding: 1em;
`;

export const ContainerModal = styled.div`
  width: 100%;
  font-size: 15px;
  justify-content: space-around;
  margin-bottom: 1em;
  gap: 0;
`;

export const InlineContent = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  margin-bottom: 0.5em;
  gap: 10px;
`;

export const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
export const InputCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #2196f3;
  }

  &:checked + ${Slider}:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
`;
