// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  height: 580px;
  width: 430px;
  border-radius: 21px;
  overflow: hidden;

  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-image: url('/images/account/backgroud-video.jpg'); // Add your image path here
  background-size: cover; // This ensures that the background covers the entire container
  background-position: center; // This centers the background image
  background-repeat: no-repeat; // This prevents the image from repeating
  background-size: 100%;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 15.14px;
  background: rgba(142, 154, 171, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(142, 154, 171, 0.2);
  border: none;
  display: flex;
  width: 49.458px;
  cursor: pointer;
  height: 49.458px;
  padding: 15.742px 19.056px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: all 0.3s ease;
  &:hover {
    background: transparent;
    border: 1px solid rgba(142, 154, 171, 0.2);
  }
`;

export const Timer = styled.div`
  position: absolute;
  top: 20px;
  background: rgba(142, 154, 171, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(142, 154, 171, 0.2);
  right: 20px;
  color: white;
  padding: 0.2em 0.8em;
`;

export const MainVideoStream = styled.div`
  height: 100%;
  width: 100%;
  background-color: #666; // Placeholder for video stream background
`;

export const PIPWindow = styled.div`
  position: absolute;
  bottom: 120px;
  right: 20px;
  width: 100px;
  height: 150px;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
  border-radius: 16px;
`;

export const Controls = styled.div`
  position: absolute;
  background-color: #ffffff80;
  backdrop-filter: blur(6px);
  border-radius: 44px;
  height: 50px;
  bottom: 2em;
  width: 84%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  padding: 0.4em;
`;

export const ControlButton = styled.button`
  border: 1px solid #ffffff80;
  background-color: #ffffff80;
  backdrop-filter: blur(6px);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    scale: 1.1;
    backdrop-filter: blur(0px);
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: auto;
`;

export const HangUpButton = styled(ControlButton)`
  background-color: red;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ff00004f;
    backdrop-filter: blur(0px);

    scale: 0.9;

    svg {
      color: red;
    }
  }
`;
