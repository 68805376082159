import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 28px;
  border-radius: 0 0 22px 22px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  height: 45px;
  border-radius: 15px;
  width: 100%;
`;

export const Dropdown = styled.select`
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #ccc;
`;

export const InputList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 12px;
  overflow: hidden;
  background: white;

  svg {
    margin: 0 10px;
    font-size: 15px;
    color: #aaa;
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 8px;
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;

  &:focus {
    border-color: #80d0c7;
  }
`;

export const ValidateButton = styled.button`
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 15px;
  height: 50px;
  width: 100%;
  background: rgb(5, 60, 78);
  background: linear-gradient(
    90deg,
    rgba(5, 60, 78, 1) 0%,
    rgba(45, 145, 213, 1) 100%
  );
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(3, 45, 58, 1) 0%,
      rgba(35, 135, 203, 1) 100%
    );
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
`;


export const CloseButton = styled.button`
  background: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #d32f2f;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed; // use fixed to cover the whole screen
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000; // ensure this is higher than the content z-index
`;
export const ModalContent = styled.div`
  position: fixed; // use fixed to position relative to the viewport
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); // this will center the modal
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001; // ensure this is higher than the overlay
  max-width: 500px;
  width: 90%; // or a fixed width if you prefer
`;
