import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;
export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  gap: 0.4em;
  align-items: center;
  margin-bottom: 0.8em;
  svg {
    font-size: 21px;
  }
`;

export const Appointment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 120px;
  padding: 0.5em;
  cursor: pointer;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PatientAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
`;

export const PatientName = styled.h2`
  font-size: 18px;
  color: #333;
  margin-right: 10px;
`;

export const Condition = styled.span`
  font-size: 16px;
  color: #666;
  border-radius: 120px;
  background: var(--BgGr, linear-gradient(117deg, #fff -2.76%, #f1f1f1 98.46%));
  width: fit-content;
  padding: 0.2em 1em;
`;

export const Insomnia = styled.span`
  font-size: 16px;
  color: #666;
  border-radius: 120px;
  background: var(--BgGr, linear-gradient(117deg, #fff -2.76%, #f1f1f1 98.46%));
  width: fit-content;
  padding: 0.2em 1em;
`;

export const AppointmentDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const Date = styled.span`
  font-size: 16px;
  color: #666;
  margin-right: 10px;
`;

export const Status = styled.button`
  border: none;
  border-radius: 120px;
  padding: 5px 10px;
  background-color: ${({ isCancelled }) =>
    isCancelled
      ? 'linear-gradient(180deg, #00ABD1 0%, #0260A9 100%));'
      : '#cce5ff'};
  color: ${({ isCancelled }) =>
    isCancelled
      ? '#linear-gradient(180deg, #00ABD1 0%, #0260A9 100%));'
      : '#004085'};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;
