// styles.js
import styled from 'styled-components';

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 1em 2em;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.4em;
`;

export const StatusIndicator = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'green' : 'red')};
  margin-right: 10px;
  position: absolute;
  bottom: 1em;
  left: 1em;
`;

export const UserInfo = styled.div`
  font-size: 14px;
  color: #333;
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 90px;
  padding: 1em 2em;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
  max-width: 300px;
  flex-grow: 1;
  transition: box-shadow 0.2s ease-in-out;

  &:focus-within {
    box-shadow: 0 0 0 2px #29b5d7;

    svg {
      color: #29b5d7;
    }
  }

  svg {
    color: #666;
  }
`;

export const SearchBar = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  background: transparent;
  margin-left: 1em; // Optional, for spacing between the icon and the input field
`;
export const Icons = styled.div`
  display: flex;
`;

export const IconButton = styled.button`
  background: none;
  border: none;

  border-radius: 21px;
  background: linear-gradient(180deg, #ededed 0%, #fafafa 100%);
  mix-blend-mode: plus-darker;
  cursor: pointer;

  &:before {
    content: '${(props) => props.icon}';
  }
`;

export const Name = styled.h1`
  color: #7d7d7d;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  line-height: 100%;
`;

export const CRM = styled.h3`
  color: #7d7d7d;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #2dd2b4;
  color: #2dd2b4;
  border-radius: 12px;
  padding: 0.5em 1em;
  gap: 0.5em;
  font-size: 15px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  text-align: left;
  line-height: 100%; /* 15.041px */
`;

export const Status = styled.div`
  border-radius: 4px;
  background: #2dd2b4;
  display: inline-flex;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 6.13px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 6.13px */
  letter-spacing: 0.429px;
  text-transform: uppercase;
`;

export const Icon = styled.img`
  width: 38px;
  margin-right: 8px;
  height: auto;
`;
