import { default as React } from 'react';

import AdminBar from '../../components/AdminBar';
import NavigationAdmin from '../../components/NavigationAdmin';
import PatientsListing from '../../components/PatientsListing';
import * as S from './styles';

const Home = () => {
  return (
    <S.Body>
      <AdminBar />
      <S.Content>
        <NavigationAdmin />
        <PatientsListing />
      </S.Content>
    </S.Body>
  );
};

export default Home;
