// NavigationAdmin.js
import React from 'react';
import { IoMdMedical } from 'react-icons/io';
import useMe from '../../hooks/useMe';
import * as S from './styles';
const Profile = '/assets/dashboard/user-tag.svg';

const NavigationAdmin = () => {
  const { me: userInfo } = useMe();

  return (
    <S.NavBar>
      <S.StatusSection>
        <S.Icon src={Profile} alt='teste' />
        <S.UserInfo>
          <S.Status>Usuário Ativo</S.Status>
          <S.Name>{userInfo.name}</S.Name>
        </S.UserInfo>
      </S.StatusSection>
      <S.CRM>
        <IoMdMedical />
        CRM {userInfo.crm}
      </S.CRM>
      {/* <S.SearchBarWrapper>
        <FiSearch size='1.5em' />
        <S.SearchBar placeholder='O que você está procurando?' />
      </S.SearchBarWrapper> */}
    </S.NavBar>
  );
};

export default NavigationAdmin;
