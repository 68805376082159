import React, { useCallback, useEffect, useState } from 'react';
import { RiUserStarLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useMe from '../../hooks/useMe';
import { httpService } from '../../services/axios';
import * as S from './styles';

const PatientsListing = () => {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();

  const { me: userInfo } = useMe();

  useEffect(() => {
    if (userInfo.id) getPatients();
  }, [userInfo]);

  const getPatients = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Carregando...',
        text: 'Aguarde enquanto buscamos a lista de pacientes.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const id = userInfo.id;
      const { data } = await httpService.get(`/users/${id}/patients`);

      setPatients(data);
    } catch (error) {
      console.log(error);
    } finally {
      Swal.close();
    }
  }, [userInfo]);

  const handleDetail = (id) => {
    return navigate(`/patients/${id}`);
  };

  return (
    <S.Container>
      <S.Title>
        <RiUserStarLine />
        Pacientes
      </S.Title>

      {patients.map((user) => (
        <S.Appointment
          key={`patient-${user.id}`}
          onClick={() => handleDetail(user.id)}
        >
          <S.PatientInfo>
            <S.PatientAvatar
              src='/images/account/profile-avatar.jpg'
              alt={`${user.name} avatar`}
            />
            <S.PatientName>{user.name}</S.PatientName>
            {/* <S.Condition>{appointment.condition}</S.Condition> */}
            {/* <S.Insomnia>{appointment.insomnia}</S.Insomnia> */}
          </S.PatientInfo>
          {/* <S.AppointmentDetails>
             <S.Date>{appointment.date}</S.Date>
             <S.Status isCancelled={appointment.status === 'Cancelada'}>
              {appointment.status}
            </S.Status> 
          </S.AppointmentDetails> */}
        </S.Appointment>
      ))}
    </S.Container>
  );
};

export default PatientsListing;
