// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  // Add other styles as needed
`;

export const InfoItem = styled.div`
  height: 40px;
  padding: 19px;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  gap: 0.6em;
  border-radius: 39px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 15px 0px rgba(136, 162, 175, 0.1);
  border: 1px dashed #ccc;
  color: var(--Grey, #93aeba);
  font-family: 'PF Handbook Pro Regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;

  svg {
    width: 16px;
    height: auto;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const IconName = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  gap: 0.4em;
  align-items: center;
  margin-bottom: 1em;
  svg {
    width: 21px;
    height: auto;
  }
`;
