import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  min-height: auto;
  box-sizing: border-box;
  padding: 1em;
  background: #ededed;
  gap: 1em;
  height: 100vh;
`;

export const Content = styled.section`
  display: flex;
  width: 100%;
  gap: 1.2em;
  padding: 0.5em 2em 2em;
  align-content: flex-start;
  align-items: stretch;
  @media (min-width: 768px) {
    // styles for desktop
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const PrescriptionButton = styled.button`
  width: 100%;
  border-radius: 120px;
  background: var(--BlueBg, linear-gradient(180deg, #00abd1 0%, #0260a9 100%));
  box-shadow: 0px 2px 15px 0px rgba(44, 123, 183, 0.49);
  display: flex;
  border: none;
  color: #fff;
  height: 64px;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;
