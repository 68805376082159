import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminBar from '../../components/AdminBar';
import NavigationAdmin from '../../components/NavigationAdmin';
import Notes from '../../components/Notes';
import Prescriptions from '../../components/Prescriptions';
import Screening from '../../components/Screening';
import useHttpService from '../../hooks/useHttpService';
import * as S from './styles';

const RenderScreening = ({ customer }) => {
  const { data, handleRequest } = useHttpService(
    `/user/${customer?.id}/screenings/list`,
  );

  useEffect(() => {
    if (customer) {
      handleRequest('get');
    }
  }, [handleRequest]);
  return <Screening multiScreenings={data} />;
};

const RenderPrescriptions = ({ customer }) => {
  const { data, handleRequest } = useHttpService(
    `/medical-appointment/${customer?.id}/documents`,
  );

  useEffect(() => {
    if (customer) {
      handleRequest('get');
    }
  }, [handleRequest]);
  return <Prescriptions prescriptions={data} />;
};

function PatientDetail() {
  const { id } = useParams();
  const { data, handleRequest } = useHttpService(`/users/${id}?type=id`);

  useEffect(() => {
    if (id) {
      handleRequest('get');
    }
  }, [handleRequest]);

  return (
    <>
      <S.Body>
        <AdminBar />

        <S.ContentGridRow>
          <NavigationAdmin />

          <S.BodyContent>
            <S.Content>
              <S.ColumnOne>
                {!!data?.id && <Notes customer={data} isAddressComplete />}
              </S.ColumnOne>

              <S.ColumnTwo>
                {!!data?.id && (
                  <>
                    <RenderScreening customer={data} />
                    <RenderPrescriptions customer={data} />
                  </>
                )}
              </S.ColumnTwo>
            </S.Content>
          </S.BodyContent>
        </S.ContentGridRow>
      </S.Body>
    </>
  );
}

export default PatientDetail;
