import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
`;

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2em 2em;
  width: 100%;
  background-color: #fff;
  border-radius: 18px;
  fill: var(--White, #fff);
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.02));
`;

const animation = `
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const NextConsultationCard = styled(SummaryCard)`
  ${animation};

  background: var(--BlueBg, linear-gradient(180deg, #00abd1 0%, #0260a9 100%));
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.02));
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 2em;
  transition: all 0.3s ease;

  animation: scale 1s infinite;

  cursor: pointer;
  &:hover {
    scale: 1.1;
    opacity: 0.5;
  }
`;

export const CardTitle = styled.h2`
  font-size: 15px;

  color: #7e92a2;
`;

export const CardTitleBlue = styled.h2`
  font-size: 16px;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;
export const CardCount = styled.div`
  font-size: 42px;
  font-weight: bold;

  color: #092c4c;
`;

export const AvatarProfileWrapper = styled.div`
  margin-right: 5px;
  border-radius: 50%;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  height: 70px;
  display: flex;
  width: 70px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const UserInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0em;
`;

export const PatientName = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

export const ConsultationType = styled.span`
  font-size: 14px;
`;

export const TimeToConsultation = styled.span`
  font-size: 14px;
  position: absolute;
  padding: 0.4em;
  bottom: 1em;
  border-radius: 120px;
  background: var(--Grey, linear-gradient(180deg, #acc9d3 0%, #88a2af 100%));
  color: #fff;
  right: 10px;
  display: flex;
  gap: 1em;
`;

export const WatchIcon = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 20px;
`;
export const SvgIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 20px;
`;
export const PatientAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export const IconName = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  gap: 0.4em;
  align-items: center;
  margin-bottom: 0.2em;
  svg {
    font-size: 21px;
  }
`;
