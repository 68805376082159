import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  display: flex;
  gap: 0.4em;
  align-items: center;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;
export const WeekdaysContainer = styled.div`
  width: 100%;
  overflow-x: auto; // Permite a rolagem horizontal se necessário
  display: flex;
  gap: 1em; // Ajuste o espaçamento conforme necessário
  justify-content: flex-start; // Alinha itens ao início do contêiner
  align-items: center; // Centraliza os itens verticalmente

  input {
    padding: 10px;
    height: 40px;
    border-radius: 8px;
  }
`;

export const Button = styled.button`
  display: flex;
  width: fit-content;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%);
  border: none;
  color: #ffff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #29b5d7;
  }
`;

export const ButtonRetun = styled.button`
  display: flex;
  width: fit-content;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #fff 0%, #f8f8f8 100%);
  border: none;
  color: #161616;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #e32938;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

export const CardModal = styled.div`
  border: none;
  border-radius: 0px;
  width: 100%;
  padding: 4em 4em;
  gap: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f2f2;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3em;
`;

export const DefaultDuration = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  white-space: nowrap;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-family: 'PF Handbook Pro Regular', sans-serif; /* Added fallback font */
`;
