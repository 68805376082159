// Notes.js
import React from 'react';
import * as S from './styles';

const NotesIcon = '/assets/icons/notes.svg';

const calculateAge = (dob) => {
  if (!dob) return '';

  const birthDateSplit = dob?.split('/');
  const birthDate = new Date(
    birthDateSplit[2],
    birthDateSplit[1] - 1,
    birthDateSplit[0],
  );

  const diff_ms = Date.now() - birthDate.getTime();

  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

const completeAddress = (address) => {
  return `${address?.street}, ${address?.number} - ${address?.neighborhood}, ${address?.city} - ${address?.state}, ${address?.cep}`;
};

const Notes = ({ customer, isAddressComplete }) => {
  return (
    <S.Container>
      <S.Title>
        <S.Icon src={NotesIcon} alt='teste' />
        Informações sobre o Paciente
      </S.Title>
      <S.InfoRow>
        <S.Label>Nome Completo</S.Label>
        <S.Value>{customer?.name}</S.Value>
      </S.InfoRow>
      <S.InfoRow>
        <S.Label>Idade do paciente</S.Label>
        <S.Value>
          {customer?.age || calculateAge(customer?.dateOfBirth)}
        </S.Value>
      </S.InfoRow>
      <S.InfoRow>
        <S.Label>Sexo</S.Label>
        <S.Value>{customer?.gender}</S.Value>
      </S.InfoRow>
      <S.InfoRow>
        <S.Label>Endereço</S.Label>
        <S.Value>
          {isAddressComplete
            ? completeAddress(customer?.address)
            : customer?.address}
        </S.Value>
      </S.InfoRow>
      <S.InfoRow>
        <S.Label>Telefone</S.Label>
        <S.Value>{customer?.phone || customer?.whatsapp}</S.Value>
      </S.InfoRow>
      <S.InfoRow>
        <S.Label>E-mail</S.Label>
        <S.Value>{customer?.email}</S.Value>
      </S.InfoRow>
    </S.Container>
  );
};

export default Notes;
