import BlankTemplate from '../templates/Blank';

import Appointments from '../pages/Appointments';
import Home from '../pages/Home';
import Login from '../pages/Login';
import MedicalStaff from '../pages/MedicalStaff';
import Medicals from '../pages/Medicals';
import Patients from '../pages/Patients';
import Room from '../pages/Room';
import Safeweb from '../pages/Safeweb';
import Settings from '../pages/Settings';
import PatientDetail from '../pages/PatientDetail';

export const routes = [
  {
    path: '/',
    page: Login,
    title: 'Login',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/dashboard',
    page: Home,
    title: 'Home',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/home',
    page: Home,
    title: 'Home',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/medicals',
    page: Medicals,
    title: 'Home',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/appointments',
    page: Appointments,
    title: 'Home',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/room/:id/schedule/:appointment',
    page: Room,
    title: 'Dashboard',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/patients',
    page: Patients,
    title: 'Patients',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/patients/:id',
    page: PatientDetail,
    title: 'Patients',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/settings',
    page: Settings,
    title: 'Settings',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/safeweb',
    page: Safeweb,
    title: 'Safeweb',
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: '/cadastro-medico',
    page: MedicalStaff,
    title: '',
    isPublic: true,
    template: BlankTemplate,
  },
];
