import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../../../../components/Input';
import { step2Schema } from './schema';

import * as S from '../../styles';

const Step2 = ({ handleNext }) => {
  const methods = useForm({
    resolver: yupResolver(step2Schema),
  });

  const errors = methods.formState.errors;

  const submit = (values) => {
    console.log('values:::', values);
    handleNext('step2', values);
  };

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(submit)}>
        <Input
          icon='idCard'
          name='cpf'
          error={errors?.cpf?.message}
          placeholder='CPF'
          mask='999.999.999-99'
        />

        <Input
          icon='stethoscope'
          name='crm'
          error={errors?.crm?.message}
          placeholder='CRM'
        />

        <Input
          icon='stethoscope'
          name='rg'
          error={errors?.rg?.message}
          placeholder='RG'
        />

        <Input
          icon='stethoscope'
          name='dateOfBirth'
          error={errors?.dateOfBirth?.message}
          placeholder='Data de Nascimento'
          mask='99/99/9999'
        />

        <S.SendButton>Próximo</S.SendButton>
      </S.Form>
    </FormProvider>
  );
};

export default Step2;
