import { useEffect } from 'react'
import useHttpService from '../../hooks/useHttpService'
import { useSearchParams } from 'react-router-dom'
import * as S from './styles'
import { SAFEWEB_API_URL, SAFEWEB_CLIENT_ID, SAFEWEB_CODE_CHALLENGE, SAFEWEB_LIFETIME } from '../../env'
import useMe from '../../hooks/useMe'

const removeMask = (str) => str?.replace(/[^0-9]/g, '')

const Safeweb = () => {
  const [params] = useSearchParams()

  const { me: profile } = useMe()
  const { data, handleRequest } = useHttpService('/safeweb/auth')

  useEffect(() => {
    if (params.get('code')) {
      const code = params.get('code')
      console.log("code", code)
      handleRequest('get', { params: { code } })
    }
  }, [params, handleRequest])

  console.log("data", data)
  return (
    <S.Container>
      <a href={`${SAFEWEB_API_URL}/OAuth/api/v0/oauth/authorize?response_type=code&client_id=${SAFEWEB_CLIENT_ID}&lifetime=${SAFEWEB_LIFETIME}&lifetime=SAFEWEB_LIFETIME&scope=signature_session&code_challenge_method=S256&login_hint=${removeMask(profile?.cpf)}&code_challenge=${SAFEWEB_CODE_CHALLENGE}`}>autenticar</a>
      <h1>Safeweb</h1>
    </S.Container>
  )
}

export default Safeweb

// `${SAFEWEB_API_URL}/OAuth/api/v0/oauth/authorize?response_type=code&client_id=${SAFEWEB_CLIENT_ID}&lifetime=${SAFEWEB_LIFETIME}&lifetime=SAFEWEB_LIFETIME&scope=signature_session&code_challenge='S256'&login_hint=doc`
