import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
    
    @font-face {
      font-family: 'PF Handbook Pro Regular';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Regular'), url('/fonts/pf-handbook-pro/PFHandbookProRegular.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Italic'), url('/fonts/pf-handbook-pro/PFHandbookProItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Extra Thin';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Extra Thin'), url('/fonts/pf-handbook-pro/PFHandbookProExtraThin.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Extra Thin Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Extra Thin Italic'), url('/fonts/pf-handbook-pro/PFHandbookProExtraThinItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Thin';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Thin'), url('/fonts/pf-handbook-pro/PFHandbookProThin.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Thin Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Thin Italic'), url('/fonts/pf-handbook-pro/PFHandbookProThinItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Medium Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Medium Italic'), url('/fonts/pf-handbook-pro/PFHandbookProMediumItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Bold';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Bold'), url('/fonts/pf-handbook-pro/PFHandbookProBold.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Bold Italic'), url('/fonts/pf-handbook-pro/PFHandbookProBoldItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Black';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Black'), url('/fonts/pf-handbook-pro/PFHandbookProBlack.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Black Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Black Italic'), url('/fonts/pf-handbook-pro/PFHandbookProBlackItalic.woff') format('woff');
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;

    }
    
    html {
      font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
      height: 100vh;

    }
    
    body, html {
      font-family: 'PF Handbook Pro Regular', sans-serif;
      background-color: #ededed;


    }
      
    body {
      margin: 0;
      padding: 0;
    
    }

    .-no-overflow {
      overflow: hidden;
    }
    .swal2-popup {
  border-radius: 20px; /* Example radius, adjust as needed */
  padding: 3em 0;
}

.swal2-title {
margin: 0;
}

/* Customize button border radius */
.swal2-confirm,
.swal2-cancel {
  border-radius: 10px !important; /* Example radius, adjust as needed */
  padding: 1em 2em;
  width: fit-content;
  transition:all 0.3s ease;
  margin: 0;
}

.swal2-html-container {
 margin: 0  !important;
 font-size: 1.4rem !important;
 p {
  font-size: 1.2rem;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  margin: 0;
  padding: 0;
 }
}

.swal2-actions { 
  gap:0.4em;
  margin-top: 2em;
}

.swal2-icon { 
  margin: auto;
}
.swal2-confirm {
background-color: var(--Blue, #007bff)  !important;
color: var(--White, #fff);
border: none;
}

.swal2-cancel {
background-color: var(--White, #f2f2f2)  !important;
color: #40555E !important;
border: none;
}
input { 
  font-family: 'PF Handbook Pro Regular', sans-serif;
  outline: none;
border: none;
  :focus { 
    border: none !important;
  }
}


textarea { 
  font-family: 'PF Handbook Pro Regular', sans-serif;
  outline: none;
border: none;
  :focus { 
    border: none !important;
    outline: none !important;
  }
}

`;
