import { useCallback, useEffect, useRef, useState } from "react";
import { LuVideoOff } from "react-icons/lu";
import { SlCallOut } from "react-icons/sl";
import Video from "twilio-video";

import { httpService } from "../../services/axios";

import Swal from "sweetalert2";
import useMe from "../../hooks/useMe";
import * as S from "./styles";
import {
  PrescriptionProvider,
  usePrescription,
} from "../../providers/PrescriptionContext";

let currentRoom = null;

const VideoChat = ({ name, roomName, appointment }) => {
  const localVideoRef = useRef();
  const localMediaStream = useRef();
  const { hasPrescripted, setHasStartedMeet } = usePrescription();
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isDisconnected, setIsDisconnected] = useState(true);

  const [isRemoteAudioEnabled, setIsRemoteAudioEnabled] = useState(true);
  const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);

  const [localUserInfo, setLocalUserInfo] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const [userDisconnected, setUserDisconnected] = useState(false);

  const [finalized, setFinalized] = useState(false);

  const { me: medicalInfo } = useMe();

  const updateSchedule = async (status, doctor, appointment) => {
    await httpService.put(`/medical-appointment/${appointment}`, {
      status,
      doctor,
    });
  };

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      localMediaStream.current = stream; // eslint-disable-line
      localVideoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  };

  const muteOrUnmuteYourMedia = (room, kind, action) => {
    const publications =
      kind === "audio"
        ? room?.localParticipant?.audioTracks
        : room?.localParticipant?.videoTracks;

    console.log(
      "? room?.localParticipant?.audioTracks",
      room?.localParticipant
    );
    console.log("publications", publications);

    publications?.forEach((publication) => {
      console.log("publication", publication);
      if (action === "mute") {
        publication?.track?.disable();
      } else {
        publication.track.enable();
      }
    });
  };

  const toggleMute = () => {
    const muteOrUnmute = !isAudioEnabled ? "unmute" : "mute";
    muteOrUnmuteYourMedia(currentRoom, "audio", muteOrUnmute);
    setIsAudioEnabled(!isAudioEnabled);
  };

  const toggleVideo = () => {
    localMediaStream.current?.getVideoTracks().forEach((track) => {
      track.enabled = !track.enabled;

      const muteOrUnmute = track.enabled ? "unmute" : "mute";
      muteOrUnmuteYourMedia(currentRoom, "video", muteOrUnmute);
      setIsVideoEnabled(!isVideoEnabled);
    });
  };

  useEffect(() => {
    if (!isDisconnected) {
      startVideo();
    }

    if (isDisconnected) {
      localMediaStream &&
        localMediaStream.current?.getTracks().forEach((track) => track.stop());
    }

    return () => {
      localMediaStream &&
        localMediaStream.current?.getTracks().forEach((track) => track.stop());
      
      // if (currentRoom) {
      //   currentRoom.disconnect();
      //   currentRoom = null;
      // }

      // localMediaStream.current = null;
    }
  }, [isDisconnected]);

  useEffect(() => {
    return () => {
      if(currentRoom != null){
        console.log('handleLeave', new Date().toISOString());
        currentRoom.disconnect();
        currentRoom = null;
      }
    }
  }, []);

  function participantConnected(participant) {
    setUserDisconnected(false);
    console.log('Participant "%s" connected', participant.identity);

    const div = document.createElement("div");
    div.id = participant.sid;

    setRemoteUser(participant.identity);

    participant.on("trackSubscribed", (track) => trackSubscribed(div, track));
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on("trackDisabled", (track) => trackDisabled(div, track));
    participant.on("trackEnabled", (track) => trackEnable(div, track));

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    setTimeout(() => {
      document.getElementById("remote-media").appendChild(div);
    }, 100);
  }

  function participantDisconnected(participant) {
    console.log('Participant "%s" disconnected', participant.identity);
    document.getElementById(participant.sid)?.remove();
    setRemoteUser(null);
    setUserDisconnected(true);
  }

  function trackSubscribed(div, track) {
    div.appendChild(track.attach());
  }

  function trackUnsubscribed(track) {
    track.detach().forEach((element) => element?.remove());
  }

  function trackDisabled(div, track) {
    if (track.kind === "audio") {
      setIsRemoteAudioEnabled(false);
    } else {
      setIsRemoteVideoEnabled(false);
      div.classList.add("-disabled");
    }
  }

  function trackEnable(div, track) {
    console.log("track", track);

    if (track.kind === "audio") {
      setIsRemoteAudioEnabled(true);
    } else {
      setIsRemoteVideoEnabled(true);
      div.classList?.remove("-disabled");
    }
  }

  const handleAddParticipant = useCallback(
    async (roomName, id, name) => {
      if (!medicalInfo?.id) {
        return Swal.fire({
          title: "Aguarde...",
          text: "Estamos configurando a sala...",
          icon: "info",
          confirmButtonText: "Ok",
        });
      }
      const { data, withError } = await httpService.post(
        `${process.env.REACT_APP_API_URL_MIDDLEWARE}/medical-appointment/generate-token/doctor`,
        {
          name,
          id: String(id),
          room: roomName,
        }
      ).catch((error) => {
        if (error.response?.status === 403) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Você não tem permissão para acessar esta sala, caso o erro persista, entre em contato com o suporte.",
          });

          return { withError: true };
        }
      });

      if(withError) return;

      if (data) {
        setHasStartedMeet(true);
      }

      Video.connect(data, { name: roomName }).then((room) => {
        const doctor = medicalInfo.id;

        updateSchedule("inmeeting", doctor, appointment);

        setIsDisconnected(false);
        currentRoom = room;

        setLocalUserInfo(room.localParticipant);

        room.participants.forEach(participantConnected);
        room.on("participantConnected", participantConnected);

        room.on("participantDisconnected", participantDisconnected);
        room.once("disconnected", (error) =>
          room.participants.forEach(participantDisconnected)
        );
      });
    },
    [appointment, medicalInfo]
  );

  useEffect(() => {
    return () => {
      if (currentRoom) {
        currentRoom.disconnect();
        currentRoom = null;

        // when the user leaves the room, force close the microfone and video local stream
        localMediaStream &&
          localMediaStream.current?.getTracks().forEach((track) => track.stop());

        
      }
    };
  }, []);

  const handleLeave = useCallback(() => {
    if (currentRoom) {
      Swal.fire({
        title: "Deseja finalizar a consulta?",
        html: "<p>Esta ação não pode ser desfeita.</p>", // Exemplo de conteúdo HTML
        icon: "question", // Ícone personalizado
        showCancelButton: true,
        confirmButtonColor: "#29b5d7", // Cor personalizada para o botão confirmar
        cancelButtonColor: "#88a2af", // Cor personalizada para o botão cancelar
        confirmButtonText: "Sim, finalizar", // Texto personalizado para o botão confirmar
        cancelButtonText: "Não, cancelar", // Texto personalizado para o botão cancelar
        reverseButtons: true, // Inverte a ordem dos botões
        customClass: {
          title: "custom-title", // Classe CSS personalizada para o título
          content: "custom-content", // Classe CSS personalizada para o conteúdo
          actions: "custom-actions", // Classe CSS personalizada para os botões
        },
        // Adicione outras opções conforme necessário
      }).then(async (result) => {
        if (result.isConfirmed) {
          const doctor = medicalInfo.id;

          currentRoom.disconnect();
          currentRoom = null;

          Swal.fire({
            title: "Finalizando consulta",
            text: "Por favor, aguarde...",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
            allowOutsideClick: false,
          });

          await updateSchedule("done", doctor, appointment);
          Swal.close();
          setFinalized(true);
          setIsDisconnected(true);
        }
      });
    }
  }, [appointment, medicalInfo]);

  // useEffect(() => {
  //   if (name && roomName && appointment) {
  //     handleAddParticipant(roomName, appointment, name)
  //   }
  // }, [name, roomName, appointment])

  if (finalized) {
    return (
      <S.Disconnected>
        Consulta Encerrada
        <LuVideoOff />
      </S.Disconnected>
    );
  }

  if (isDisconnected) {
    return (
      <S.EnterMeeting
        onClick={() => handleAddParticipant(roomName, appointment, name)}
      >
        {medicalInfo?.id ? "Iniciar Consulta" : "Configurando consulta..."}

        <SlCallOut />
      </S.EnterMeeting>
    );
  }

  return (
    <PrescriptionProvider>
      <S.Container>
        {userDisconnected && (
          <S.DisconnectedUser>Usuário desconectado</S.DisconnectedUser>
        )}
        <S.Remote id="remote-media" />

        <S.Me>
          <S.Top>
            {remoteUser && (
              <S.RemoteUserName>
                {remoteUser}
                <S.MicrophoneAction muted={!isRemoteAudioEnabled} />
                <S.VideoAction muted={!isRemoteVideoEnabled} />
              </S.RemoteUserName>
            )}

            <S.MeInfo>
              <S.Video ref={localVideoRef} autoPlay={true} />
              <S.MeName>{localUserInfo?.identity}</S.MeName>
            </S.MeInfo>
          </S.Top>

          <S.Actions>
            <S.EndCall disabled={!hasPrescripted} onClick={handleLeave} />
            <S.MicrophoneAction muted={!isAudioEnabled} onClick={toggleMute} />
            <S.VideoAction muted={!isVideoEnabled} onClick={toggleVideo} />
          </S.Actions>
        </S.Me>
      </S.Container>
    </PrescriptionProvider>
  );
};

export default VideoChat;