import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { httpService } from "../../services/axios";
import Input from "../Input";
import * as S from "./styles";

const CreateCompromisse = ({ isOpen, closeModal }) => {
  const defaultValues = {};

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      console.log("data", data);
      Swal.fire({
        title: "Criando um compromisso...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      // O formato de dateTime deve ser YYYY-MM-DDTHH:mm:ss.SSSZ
      const date = data.date;
      const time = data.time;

      const transformedDate = new Date(`${date}T${time}:00.000Z`);

      await httpService.post("/doctor-schedules/unavailabilities", {
        dateTime: transformedDate.toISOString(),
        duration: parseInt(data.duration),
      });

      Swal.fire({
        title: "Compromisso criado com sucesso",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      closeModal();
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Erro ao criar o compromisso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  };

  if (isOpen)
    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.Modal>
            <S.CardModal>
              <S.ModalImage src="/images/medical-staff-bg.png" alt="teste" />
              <S.Content>
                <S.Title>
                  Defina um período de ausência em nossa plataforma, isso é
                  importante para evitar o não atendimento de clientes
                </S.Title>

                <S.WeekdaysContainer>
                  <S.Item>
                    <S.Label>Dia</S.Label>
                    <Input
                      type="date"
                      name="date"
                      {...methods.register("date")}
                    />
                  </S.Item>

                  <S.Item>
                    <S.Label>Qual horário?</S.Label>

                    <Input
                      type="time"
                      name="time"
                      {...methods.register("time")}
                    />
                  </S.Item>

                  <S.Item>
                    <S.Label>Quanto tempo? (em minutos)</S.Label>

                    <Input
                      type="number"
                      name="duration"
                      min="0"
                      {...methods.register("duration")}
                    />
                  </S.Item>
                </S.WeekdaysContainer>

                <S.FlexContainer>
                  <S.ButtonRetun onClick={closeModal}>
                    <IoCloseOutline />
                    Retornar
                  </S.ButtonRetun>

                  <S.Button type="submit">
                    <FiSave />
                    Confirmar
                  </S.Button>
                </S.FlexContainer>
              </S.Content>
            </S.CardModal>
          </S.Modal>
        </form>
      </FormProvider>
    );
};
export default CreateCompromisse;
