// DailyAppointments.js
import React, { useCallback, useEffect, useState } from 'react';
import { CiMedicalCase, CiMedicalCross } from 'react-icons/ci';
import { TbMedicalCrossCircle } from 'react-icons/tb';
import { httpService } from '../../services/axios';
import * as S from './styles';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import Swal from 'sweetalert2';
import useMe from '../../hooks/useMe';
import CreateCompromisse from '../CreateCompromisse';
import CreateSchedule from '../CreateSchedule';

const AppointmentsIcon = '/assets/icons/agendamentos.svg';

// const events = [
//   { title: 'Meeting', start: new Date() }
// ]

function renderEventContent(eventInfo) {
  return (
    <div>
      {/* <b>{eventInfo.timeText}</b> */}
      <i>Paciente: {eventInfo.event.title}</i>
    </div>
  );
}

const DailyAppointments = () => {
  const [events, setEvents] = useState([]);
  const [filterDate, setFilterDate] = useState(new Date());
  const [appointments, setAppointments] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalCompromisse, setModalCompromisse] = useState(false);

  const { me: medicalInfo } = useMe();

  const [schedules, setSchedules] = useState(null);
  const [compromisses, setCompromisses] = useState(null);

  const handleGetAppointments = async () => {
    const { data } = await httpService.get('/users/schedule/appointments');
    setSchedules(data);
  };

  const handleGetDoctorSchedules = async () => {
    Swal.fire({
      title: 'Carregando...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
    const { data: compromisse } = await httpService.get(
      '/doctor-schedules/unavailabilities',
    );
    setCompromisses(compromisse);

    Swal.close();
  };

  const handleAddCompromisseInCalendar = useCallback(() => {
    const compromissesEvents = compromisses.map((compromisse) => {
      const dateTime = new Date(compromisse.attributes.dateTime);

      dateTime.setHours(dateTime.getHours() + 3);

      return {
        id: `compromisse-${compromisse.id}`,
        title: 'Compromisso',
        start: dateTime.toISOString(),
        end: new Date(
          dateTime.getTime() + compromisse.attributes.duration * 60000,
        ).toISOString(),
      };
    });

    const removeDuplicateEvents = compromissesEvents.filter(
      (compromisseEvent) => {
        return !events.some((event) => event.id === compromisseEvent.id);
      },
    );

    setEvents([...events, ...compromissesEvents]);
  }, [events, compromisses]);
  useEffect(() => {
    if (compromisses) {
      handleAddCompromisseInCalendar();
    }
  }, [compromisses]);

  const handleAddSchedulesInCalendar = useCallback(() => {
    const scheduledEvents = schedules.map((schedule) => {
      const dateTime = new Date(schedule.attributes.scheduleDate);
      let title = String(schedule.attributes.customer.data.attributes.name) ?? '';
      // dateTime.setHours(dateTime.getHours() + 3);
      if(title.includes('@'))
        title = title.split('@')[0];

      return {
        id: `schedule-${schedule.id}`,
        title,
        start: dateTime.toISOString(),
        end: new Date(dateTime.getTime() + 30 * 60000).toISOString(),
      };
    });

    const removeDuplicateEvents = scheduledEvents.filter((scheduledEvent) => {
      return !events.some((event) => event.id === scheduledEvent.id);
    });

    setEvents([...events, ...removeDuplicateEvents]);
  }, [schedules, events]);

  useEffect(() => {
    if (schedules) {
      handleAddSchedulesInCalendar();
    }
  }, [schedules]);

  useEffect(() => {
    if (!showModal || !modalCompromisse) {
      handleGetDoctorSchedules();
      handleGetAppointments();
    }
  }, [showModal, modalCompromisse]);

  useEffect(() => {
    if (filterDate && medicalInfo.id) getPatients();
  }, [filterDate]);

  const getPatients = useCallback(async () => {
    try {
      const id = medicalInfo.id;
      const isoString = filterDate.toISOString();
      const { data } = await httpService.get(
        `/users/${id}/schedule-appointments`,
        {
          id: id,
          role: 3,
          schedule_date: isoString,
        },
      );
      setAppointments(data.response);
      console.log(data.response);
    } catch (error) {
    } finally {
    }
  }, []);

  const transformHours = (value) => {
    const date = new Date(value);
    const hour = date.toLocaleString();
    return hour;
  };

  const modalOn = () => {
    setShowModal(true);
  };

  const modalOff = () => {
    setShowModal(false);
  };

  const toggleModalCompromisse = () => {
    setModalCompromisse(!modalCompromisse);
  };

  return (
    <S.Container>
      <S.TitleHeader>
        <S.Title>
          <CiMedicalCross />
          Seus agendamentos
        </S.Title>

        <S.Actions>
          <S.ButtonHours onClick={modalOn}>
            <TbMedicalCrossCircle />
            Meus Horários
          </S.ButtonHours>
          <S.Button onClick={toggleModalCompromisse}>
            <CiMedicalCase />
            Novo compromisso
          </S.Button>
        </S.Actions>
      </S.TitleHeader>

      <CreateSchedule isOpen={showModal} closeModal={modalOff} />
      <CreateCompromisse
        isOpen={modalCompromisse}
        closeModal={toggleModalCompromisse}
      />

      <S.Calendar>
        <FullCalendar
          locale={ptBrLocale}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView='timeGridWeek'
          events={events}
          allDaySlot={false}
          height={480}
          slotMinTime='00:00:00'
          eventBackgroundColor='#2dd2b4'
          slotMaxTime='23:59:00'
          eventContent={renderEventContent}
          eventClick={(event) => {
            const id = event.event.id;
            // aqui voce pode buscar as info de evento e redirecionar para uma room, tanto aqui quanto no cliente
            console.log('id', id);
          }}

        />

        <S.AppointmentList>
          {appointments?.length > 0 ? (
            appointments?.map(() => (
              <S.AppointmentItem key={appointments?.id}>
                <S.PatientAvatar
                  src='/images/account/profile-avatar.jpg'
                  alt={` avatar`}
                />
                <S.PatientInfo>
                  <S.PatientName>
                    {appointments?.customer?.username}
                  </S.PatientName>
                  {/* <S.AppointmentType>
                  {appointments?.}
                </S.AppointmentType> */}
                </S.PatientInfo>
                <S.AppointmentTime>
                  {transformHours(appointments?.attributes?.scheduleDate)}
                </S.AppointmentTime>
              </S.AppointmentItem>
            ))
          ) : (
            <div></div>
            // <S.NoAppointments>
            //   <TbClockCancel />
            //   Nenhum agendamento para este dia.
            // </S.NoAppointments>
          )}
        </S.AppointmentList>
      </S.Calendar>
    </S.Container>
  );
};

export default DailyAppointments;
