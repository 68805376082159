import { useState, useCallback } from 'react';
import { httpService } from '../../services/axios';

function useHttpService(path) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  
  const handleRequest = useCallback(async (method, params) => {
    try {
      setLoading(true);
      const { data } = await httpService[method](path, params);
      setData(data); 

      return data
    } catch (error) {
      console.log("error", error)
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [path])

  return {
    data,
    loading,
    error,
    handleRequest
  };
}

export default useHttpService;
