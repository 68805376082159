import { default as React } from 'react';

import AdminBar from '../../components/AdminBar';
import NavigationAdmin from '../../components/NavigationAdmin';
// import useGetAvailableRoom from '../../hooks/useGetAvailableRoom';
// import Swal from 'sweetalert2';
import MedicalListing from '../../components/MedicalListing';
import * as S from './styles';

const Home = () => {
  // const room = useGetAvailableRoom();

  // const goToRoom = () => {
  //   navigate(`/room/${room.attributes.room}/schedule/${room.id}`);
  // };

  // const handleOpenModal = () =>
  //   Swal.fire({
  //     title: 'Você tem uma consulta disponível',
  //     text: 'Deseja entrar na sala?',
  //     icon: 'info',
  //     showCancelButton: true,
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Não',
  //   }).then(({ isConfirmed }) => {
  //     if (isConfirmed) {
  //       goToRoom();
  //     }
  //   });

  // useEffect(() => {
  //   if (room?.id) {
  //     handleOpenModal();
  //   }
  // }, [room]);
  return (
    <S.Body>
      <AdminBar />

      <S.Content>
        <NavigationAdmin />
        <MedicalListing />
      </S.Content>
    </S.Body>
  );
};

export default Home;
