import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { differenceInDays } from "date-fns";
import { CiStickyNote } from "react-icons/ci";
import Swal from "sweetalert2";
import { httpService } from "../../services/axios";
import useMe from "../../hooks/useMe";

const Triagem = "/assets/icons/screening.svg";

const MedicalAnnotations = ({ customer }) => {
  const [notes, setNotes] = useState('');
  const { me: userInfo } = useMe();
  const [annotations, setAnnotations] = useState([]);

  useEffect(() => {
    const handleGetAnnotations = async () => {
      const { data } = await httpService.get('/medical-notes?email=' + customer?.email)
      setAnnotations(data?.data.length > 0 ? data.data.map(v => ({
        doctor: v.attributes.doctor,
        annotation: v.attributes.annotation,
        createdAt: new Date(v.attributes.updatedAt),
      })) : [])
    }
    handleGetAnnotations()
  }, [customer?.email]);
  
  const handleSave = async () => {
    if(notes.length > 15){
      setAnnotations(prev => [...prev, {
        doctor: userInfo?.name,
        annotation: notes,
        createdAt: new Date(),
      }])
      setNotes('')
      await httpService.post('/medical-notes', {
        "annotation": notes,
        "doctor": userInfo?.name,
        "customer": customer?.email
      })
    } else {
      Swal.fire({
        title: "Oops!",
        text: "A anotação deve ter no mínimo 15 caracteres.",
        icon: "info",
        confirmButtonText: "Ok",
      });
    }
  }

  return (
    <S.Container>
      <S.Title>
        <S.Icon src={Triagem} alt="Triagem" />
        Anotações médicas
      </S.Title>
      <S.ConditionList>
        {annotations?.map((v, i) => {
          const daysAgo = differenceInDays(new Date(), new Date(v.createdAt));
          return (
            <div key={`${i}-info-row-medical-ann`} style={{ width: '100%' }}>
              <S.InfoRow>
                <S.Label>Médico:</S.Label>
                <S.Value>{v.doctor}</S.Value>
              </S.InfoRow>
              <S.InfoRow>
                <S.Label>Anotações:</S.Label>
                <S.Value>{v.annotation}</S.Value>
              </S.InfoRow>
              <S.InfoRow>
                <S.Label>Data:</S.Label>
                <S.Value>
                  {daysAgo === 0 ? "hoje" : daysAgo + " dias atrás"}
                </S.Value>
              </S.InfoRow>
            </div>
          );
        })}
      </S.ConditionList>
      <S.InputWrapper>
        <CiStickyNote />
        <S.ObservationsField
          placeholder="Escreva uma anotação... (opcional)"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      <S.ButtonEdit onClick={handleSave}>Salvar</S.ButtonEdit>
      </S.InputWrapper>
    </S.Container>
  );
};

export default MedicalAnnotations;
