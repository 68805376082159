import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminBar from "../../components/AdminBar";
import NavigationAdmin from "../../components/NavigationAdmin";
import Notes from "../../components/Notes";
import Prescriptions from "../../components/Prescriptions";
import Screening from "../../components/Screening";
import VideoCall from "../../components/VideoCall";
import useHttpService from "../../hooks/useHttpService";
import PrescriptionGenerator from "./PrescriptionGenerator"; // Ensure correct path
import * as S from "./styles";
import { httpService } from "../../services/axios";
import ButtonPatientOut from "../../components/ButtonPatientOut";
import { PrescriptionProvider } from "../../providers/PrescriptionContext";
import Swal from "sweetalert2";
import ButtonPatientWhitoutPrescription from "../../components/ButtonPatientWhitoutPrescription";
import MedicalAnnotations from "../../components/MedicalAnnotations";

function Dashboard() {
  const { id, appointment } = useParams();
  const navigate = useNavigate();
  const { data, handleRequest } = useHttpService(
    `/dashboard/schedule/${appointment}`
  );

  const handleLeaveQueue = async (appointmentId, isDocAfk = false, status = 'scheduled') => {
    try {
      let data = {
        status,
      };

      if (!isDocAfk) {
        data.isInQueue = false
        data.enteredQueueAt = null;
      }
      await httpService.put(`/medical-appointment/${appointmentId}/queue`, data);

      if(status === 'canceled') {
        await httpService.put(`/medical-appointment/${appointment}`, {
          status,
        });
      }

      navigate(`/dashboard`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data?.notAllowed) {
      Swal.fire({
        title: "Oops!",
        text: data?.message,
        icon: "info",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate(`/dashboard`);
      })
    }
  }, [data])

  useEffect(() => {
    if (appointment) {
      handleRequest("get");
    }
  }, [appointment]);

  const disabled = !data || data?.notAllowed;
  return (
    <PrescriptionProvider>
      <S.Body style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
        <AdminBar />
        <S.ContentGridRow>
          <NavigationAdmin />
          <S.BodyContent>
            <S.Content>
              <S.ColumnTwo>
                <Screening
                  customer={data?.customer}
                  screenings={data?.screenings}
                />
                <Notes customer={data?.customer} />
                {/* <Prescriptions prescriptions={data?.documents} /> */}
                <MedicalAnnotations customer={data?.customer} />
              </S.ColumnTwo>
              <S.ColumnOne>
                {data?.products && (
                  <PrescriptionGenerator
                    appointment={appointment}
                    products={data?.products}
                  />
                )}
              </S.ColumnOne>
            </S.Content>

            <VideoCall id={id} handleLeaveQueue={handleLeaveQueue} appointment={appointment} />
          </S.BodyContent>
          <S.ContentButtons>
            <ButtonPatientOut handleLeaveQueue={handleLeaveQueue} appointment={appointment} />
            <ButtonPatientWhitoutPrescription handleLeaveQueue={handleLeaveQueue} appointment={appointment} />
          </S.ContentButtons>
        </S.ContentGridRow>
      </S.Body>
    </PrescriptionProvider>
  );
}

export default Dashboard;