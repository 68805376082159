import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
`;

export const ConditionList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  margin: 0px 0px 20px 0;
  display: flex;
  gap: 0.4em;
  align-items: center;
`;

export const PatientInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const InfoRow = styled.div`
  display: flex;

  gap: 0.3em;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const Label = styled.span`
  color: #aaa;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-right: 0.3em;
  padding: 0.3em 0.6em;
  border-radius: 5px;
  background: #e5ebf0;
`;

export const Value = styled.span`
  flex-grow: 1;
  color: #aaa;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 0.3em;
  border-radius: 5px;
  background: #f6f7f7;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;

  align-items: center;
  background-color: #f2f2f2;
  border-radius: 12px;
  svg {
    font-size: 24px;
    margin: auto 0px auto 15px;

    color: #667c89;
  }
  overflow: hidden;

  :active {
    background-color: #f2f2f2;
  }
`;

export const ButtonEdit = styled.button`
  background-color: #00abd1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-size: 0.6em;
  border: none;
  border-radius: 15px;
  color: #fff;
  padding: 10px 20px;
  height: 40px;
  cursor: pointer;
  width: 50px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0260a9;
  }

  svg {
    font-size: 20px;
  }
`;

export const ObservationsField = styled.input`
  border-radius: 12px;
  width: 100%;
  border: 1px solid #d0d0d0;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-family: 'PF Handbook Pro Regular', sans-serif;

  padding: 0.3em 1em 0;
  height: 45px;
  overflow: hidden;

  &:focus {
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.6);
    border-color: rgba(0, 123, 255, 0.5);
  }
`;