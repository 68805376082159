import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { httpService } from "../../services/axios";

function Blank({ children }) {
  const location = useLocation();
  const prevLocationRef = useRef(null);

  async function monitorRoomUrl(appointmentId){
    // consultar o appointment e verificar se ele é != de done
    try {
    const { data: { data: { attributes: { status } }} } = await httpService.get(`/medical-appointment/${appointmentId}/status`);
      // se for != então set o appointment para schedule
      if(status != 'done' && status != 'canceled'){
        await httpService.put(`/medical-appointment/${appointmentId}/queue`, {
          status: "scheduled",
        })
      }
    } catch (error) {
      console.log("Erro: monitorRoomUrl", error);
    }
  }

  useEffect(() => {
    // prevent change current url if current page is /room/:id, go to other page if user click on ok
    window.addEventListener("popstate", function (e) {
      if (window.location.pathname.includes("/room/")) {
        e.preventDefault();
        const confirm = window.confirm("Você tem certeza que deseja sair desta página?");
        if (confirm) {
          window.history.back();
        }
      }
    });

    return () => {
      window.removeEventListener("popstate", function (e) {
        if (window.location.pathname.includes("/room/")) {
          e.preventDefault();
          const confirm = window.confirm("Você tem certeza que deseja sair desta página?");
          if (confirm) {
            window.history.back();
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (prevLocationRef.current) {
      const prevUrl = prevLocationRef.current.pathname;
      if (prevUrl.includes("/room/")) {
        const idMatch = prevUrl.match(/\/room\/[^\/]+\/schedule\/(\d+)/);
        if (idMatch) {
          const id = idMatch[1];
          monitorRoomUrl(id)
        }
      }
    }
    prevLocationRef.current = location;
  }, [location]);

  return children;
}

export default Blank;
