import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;

  .fc {
    width: 100%;
    font-size: 15px;

    * {
      font-size: 12px;
    }
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0em;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1em;
`;

export const Header = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

export const Weekdays = styled.div`
  display: flex;
  font-size: 15px;
  justify-content: space-around;

  gap: 0;
`;
export const Weekday = styled.div`
  padding: 10px 20px;
  display: flex;
  width: 13.5%;
  font-size: 14px;
  padding: 5px 10px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  // ... existing styles ...
  background-color: ${({ isSelected }) => (isSelected ? '  #00ABD1' : '#fff')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#333')};
  // ... other existing styles ...

  &:hover {
    background-image: linear-gradient(
      to right,
      #00abd1 0%,
      #29b5d7 100%
    ); // Example gradient
    color: #fff;
  }
`;

export const AppointmentList = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  gap: 0.9em;
`;

export const AppointmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 120px;

  background: var(
    --WhiteGd,
    linear-gradient(117deg, #fff -2.76%, #f1f1f1 98.46%)
  );
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.02));

  &:last-child {
    border-bottom: none;
  }
`;

export const PatientInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const PatientName = styled.h3`
  font-size: 16px;
  color: #333;
`;

export const AppointmentType = styled.span`
  font-size: 14px;
  color: #666;
`;

export const AppointmentTime = styled.span`
  font-size: 16px;
  color: #666;
  border-radius: 120px;
  background: var(--BgGr, linear-gradient(117deg, #fff -2.76%, #f1f1f1 98.46%));
  padding: 0.5em 1em;
  box-shadow: (4px 4px 15px rgba(0, 0, 0, 0.05));
`;

export const NoAppointments = styled.span`
  text-align: center;
  color: #666;
  padding: 0.8em 1em;
  gap: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 0em;
  border-radius: 25px;
  margin-bottom: 2em;
  svg {
    font-size: 20px;
    color: #28b3d6;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  gap: 0.4em;
  align-items: center;
  margin-bottom: 0.3em;
  svg {
    font-size: 21px;
  }
`;

export const PatientAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
`;

export const ButtonHours = styled.button`
  display: flex;
  width: fit-content;
  font-size: 15px;
  cursor: pointer;
  gap: 0.5em;
  padding: 0.1em 0.5em;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: transparent;
  color: #01c2ef;
  border: 1px solid #01c2ef;
  font-family: 'PF Handbook Pro';
  height: 23px;
  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    color: #fff;
    background-color: #01c2ef;
  }

  svg {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  display: flex;
  width: fit-content;
  font-size: 15px;
  cursor: pointer;
  gap: 0.5em;
  padding: 0.1em 0.5em;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  font-family: 'PF Handbook Pro';
  height: 23px;
  background: #01c2ef;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border: none;
  color: #fff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #29b5d7;
  }

  svg {
    color: #fff;
    font-size: 20px;
  }

  &:hover svg {
    color: #29b5d7;
  }

  &:hover p {
    display: block;
  }
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #fff;
  padding: 2em 3em;
  border-radius: 18px;
`;
