// ProfileCover.jsx
import React from 'react';
import { GrOverview } from 'react-icons/gr';
import * as S from './styles'; // Import styled components

const ProfileCover = ({ name, crm, status }) => {
  return (
    <>
      {' '}
      <S.Title>
        <GrOverview />
        Meu Painel
      </S.Title>
      <S.Container>
        <S.ProfileSection>
          <S.InfoSection>
            <S.StatusBadge active={true}>{status}</S.StatusBadge>
            <S.Name>{name}</S.Name>
            <S.CRM>CRM {crm}</S.CRM>
          </S.InfoSection>
        </S.ProfileSection>
      </S.Container>
    </>
  );
};

export default ProfileCover;
