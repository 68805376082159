import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CiPause1 } from 'react-icons/ci';
import {
  HiOutlineCalendar,
  HiOutlineClipboardList,
  HiOutlineHome,
  HiOutlineIdentification,
  HiOutlineLockClosed,
  HiOutlineMail,
  HiOutlineMap,
  HiOutlinePhone,
  HiOutlineUser,
} from 'react-icons/hi';
import { IoPlayOutline, IoReturnUpBackOutline } from 'react-icons/io5';
import InputMask from 'react-input-mask';
import * as S from './styles'; // Ensure this path is correct

const iconComponents = {
  envelope: <HiOutlineMail />,
  user: <HiOutlineUser />,
  lock: <HiOutlineLockClosed />,
  phone: <HiOutlinePhone />,
  email: <HiOutlineMail />,
  postalCode: <HiOutlineMap />,
  home: <HiOutlineHome />,
  mapMarker: <HiOutlineMap />,
  idCard: <HiOutlineIdentification />,
  stethoscope: <HiOutlineClipboardList />, // Assuming medical context; adjust as needed
  neighborhood: <HiOutlineHome />, // Adjust as needed
  number: <HiOutlineHome />,
  state: <HiOutlineMap />,
  city: <HiOutlineMap />,
  crm: <HiOutlineClipboardList />, // Assuming medical context; adjust as needed
  rg: <HiOutlineIdentification />,
  dateOfBirth: <HiOutlineCalendar />,
  clock: <HiOutlineCalendar />,
  returnHour: <IoReturnUpBackOutline />,
  startHour: <IoPlayOutline />,
  pause: <CiPause1 />,
};

const Input = ({
  name,
  type = 'text',
  placeholder = '',
  error,
  mask,
  icon,
  ...props
}) => {
  const { register } = useFormContext();
  const IconComponent = icon ? iconComponents[icon] : null; // Direct component usage

  return (
    <S.InputIconWrapper error={error}>
      {IconComponent && <S.Icon>{IconComponent}</S.Icon>}
      {mask ? (
        <InputMask mask={mask} maskChar=' ' {...props} {...register(name)}>
          {(inputProps) => (
            <S.Input {...inputProps} placeholder={placeholder} type={type} />
          )}
        </InputMask>
      ) : (
        <S.Input
          {...register(name)}
          {...props}
          placeholder={placeholder}
          type={type}
        />
      )}
      {error && <S.Error>{error}</S.Error>}
    </S.InputIconWrapper>
  );
};

export default Input;
