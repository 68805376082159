import { default as React } from 'react';

import AdminBar from '../../components/AdminBar';
import DailyAppointments from '../../components/DailyAppointments';
import NavigationAdmin from '../../components/NavigationAdmin';
import Overview from '../../components/Overview';
import PatientsListing from '../../components/PatientsListing';
import * as S from './styles';

const Home = () => {
  return (
    <S.Body>
      <AdminBar />

      <S.Content>
        <NavigationAdmin />
        <S.DoubleRow>
          <S.Column>
            <Overview />
            <PatientsListing />
          </S.Column>
          <S.ColumnAppointment>
            <DailyAppointments />
          </S.ColumnAppointment>
        </S.DoubleRow>
      </S.Content>
    </S.Body>
  );
};

export default Home;
