// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoRow = styled.div`
  display: flex;

  margin-bottom: 10px;
  border-radius: 5px;
`;

export const Label = styled.span`
  color: #aaa;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-right: 0.3em;
  padding: 0.3em 0.6em;
  border-radius: 5px;
  background: #e5ebf0;
`;

export const Value = styled.span`
  flex-grow: 1;
  color: #aaa;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 0.3em;
  border-radius: 5px;
  background: #f6f7f7;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  margin: 0px 0px 20px 0;
  display: flex;
  gap: 0.4em;
  align-items: center;
`;
