import React from 'react';
import { GrUserNew } from 'react-icons/gr';
import * as S from './styles';

const Check = '/assets/icons/check.svg';

const Prescriptions = ({ prescriptions }) => {
  return (
    <S.Container>
      <S.Title>
        <GrUserNew />
        Prescrições do Paciente
      </S.Title>
      <S.ConditionList>
        {prescriptions?.map((prescription, index) => (
            <S.Link href={prescription.attributes.documents} target="_blank">
          <S.ConditionItem key={`prescription-${prescription.id}-${index}`}>
            <S.Check src={Check} alt='Check' />
              {prescription.attributes.title}
          </S.ConditionItem>
            </S.Link>
        ))}
      </S.ConditionList>
    </S.Container>
  );
};

export default Prescriptions;
