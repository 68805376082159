// AdminBar.js
import React from 'react';
import { GiSettingsKnobs } from 'react-icons/gi';
import { GoCalendar } from 'react-icons/go';
import { GrOverview } from 'react-icons/gr';
import { PiUserGearLight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { TOKEN_NAME } from '../../env';
import * as S from './styles';
const logoutIcon = '/assets/icons/logout.svg';
const logo = '/assets/logo.svg';

// ... icons and logo paths

const AdminBar = () => {
  const navigate = useNavigate(); // Instantiate useNavigate

  // Handlers for each menu item click using navigate
  const goToDashboard = () => navigate('/dashboard');
  const goToPatients = () => navigate('/patients');
  const goToAppointments = () => navigate('/appointments');
  const goToSettings = () => navigate('/settings');

  const handleLogout = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem('@bluemed-dashboard:ca-exists');
    navigate('/');
  };

  return (
    <S.Container id='Menu'>
      <S.Logo src={logo} alt='Blue Med logo' />
      <S.Menu>
        <S.MenuItem onClick={goToDashboard}>
          <GrOverview />
          Overview
        </S.MenuItem>

        <S.MenuItem onClick={goToPatients}>
          <PiUserGearLight />
          Pacientes
        </S.MenuItem>

        <S.MenuItem onClick={goToAppointments}>
          <GoCalendar />
          Agendamentos
        </S.MenuItem>

        <S.MenuItem onClick={goToSettings}>
          <GiSettingsKnobs />
          Configurações
        </S.MenuItem>
      </S.Menu>

      <S.RowBottom>
        <S.Copy>Blue Med ® 2023</S.Copy>
        <S.LogoutButton onClick={handleLogout}>
          LOG<br></br>OUT <S.Logout src={logoutIcon} alt='Blue Med logo' />
        </S.LogoutButton>
      </S.RowBottom>
    </S.Container>
  );
};

export default AdminBar;
