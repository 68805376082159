import styled from "styled-components";
const envelopeIcon = "/icons/crm.svg";
const lockIcon = "/icons/lock.svg";
const userIcon = "/icons/user.svg";

const icons = {
  envelope: envelopeIcon,
  user: userIcon,
  lock: lockIcon,
};

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 2em;
`;

export const Body = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  background: var(
    --BlueNews,
    linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%)
  );
  min-height: 100vh;
  display: flex; /* Added display flex */
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  background-image: url('/images/medical-staff-bg.png');
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
`;

export const HeadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  margin-top: 4em;
  z-index: 1;
  margin-bottom: 1em;
`;

export const Title = styled.h2`
  color: #fff;
  text-align: center;
  font-family: "PF Handbook Pro Regular", sans-serif;
  font-size: 26px;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: 21px; /* 80.769% */
`;

export const SubTitle = styled.h5`
  color: #ffffff90;
  text-align: center;
  font-family: "PF Handbook Pro Regular", sans-serif;
  font-size: 21px;
  font-style: normal;
  margin: 0;
  font-weight: 300;

  line-height: normal;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  height: 100%;
  font-size: 18px;

<<<<<<< HEAD
  &::before {
    content: "";
    display: inline-block;
    margin-right: 1em;
    background: url(${(props) => icons[props.icon] || icons.user});
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
  }
=======
  &:focus {
    outline: none;
>>>>>>> f03def527efb93770886b615349b09bf1335153a

    &::before {
      content: '';
      display: inline-block;
      margin-right: 1em;
      background: url(${(props) => icons[props.icon] || icons.user});
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
    }

    input {
      border: none;
      background: transparent;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; // Add space between text and icon

  &:hover:not(:disabled) {
    // ... other styles
  }
  display: flex;
  width: 100%;
  font-family: "PF Handbook Pro Regular", sans-serif; /* Added fallback font */
  font-size: 21px;
  cursor: pointer;
  height: 60px;
  padding: 19px 33px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(
    --BlueNews,
    linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%)
  );
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border: none;
  color: #fff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }

  svg {
    font-size: 1.5em;

    &:hover {
      color: #29b5d7;
    }
  }

  transition: all 0.3s ease;
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

export const Logo = styled.img`
  width: 230px;
  z-index: 1;
  height: auto;
`;

export const LogoBackgroundAvatar = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  right: 0px;
`;

export const Forms = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 2em auto;
  justify-content: center;
  z-index: 1;
  align-items: center;
  max-width: 460px;

  @media screen and (max-width: 600px) {
    max-width: 90%;
  }
`;
export const InputIconWrapper = styled.div`
  background: #ffff;
  height: 60px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  svg {
    margin-right: 1em;
    margin-left: 1em;
    font-size: 1.8em;
    color: #717171;
  }
`;

export const MedicalStaff = styled.div`
  font-size: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
`;

export const CreateAccount = styled.a`
  color: #fff;
  font-size: 16px;
  font-family: "PF Handbook Pro Regular", sans-serif;
  font-weight: 300;
  text-decoration: none;
  margin-top: 1em;
`;