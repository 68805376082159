import { useState, useEffect } from 'react';

const useTimeOnPage = () => {
  const [timeOnPage, setTimeOnPage] = useState(0);

  useEffect(() => {
    const start = Date.now();

    const intervalId = setInterval(() => {
      setTimeOnPage(Math.floor((Date.now() - start) / 1000));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return timeOnPage;
};

export default useTimeOnPage;
