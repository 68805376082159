import { differenceInSeconds } from "date-fns";

export function isCaExpired(createdAt, expires_in) {
    //ex: expires_in = 604800 segundos 518400
    //ex: createdAt = "2024-05-26T23:55:35.972Z"
    if (expires_in === null)
        return false;

    const securityDay = 86400 // 1d;
    return differenceInSeconds(new Date(), new Date(createdAt)) > (expires_in - securityDay);
}
