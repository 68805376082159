import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  gap: 1em;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */

  display: flex;
  gap: 0.4em;
  align-items: center;
`;

export const Icon = styled.img`
  width: 24px;
  height: auto;
`;
export const WeekdaysContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 10px;
  margin-bottom: 1em;
`;

export const Item = styled.div`
  display: flex;
  font-size: 12px;
  align-items: flex-start;
  flex-direction: column;
  text-transform: uppercase;
  margin-top: 0.3em;
  color: #93aeba;
  gap: 2px;

  div {
    width: 100%;

    label {
      font-size: 15px;
    }
  }
`;
export const Button = styled.button`
  display: flex;
  width: fit-content;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%);
  border: none;
  color: #ffff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #29b5d7;
  }
`;

export const ButtonRetun = styled.button`
  display: flex;
  width: fit-content;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #fff 0%, #f8f8f8 100%);
  border: none;
  color: #161616;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #e32938;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  gap: 10px;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
export const ModalImage = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  height: 700px;
  margin: 0;
  max-height: 450px;

  overflow: hidden;
`;
export const CardModal = styled.div`
  border: none;
  border-radius: 32px;
  height: 440px;
  width: 70%;
  gap: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background-color: #f2f2f2;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DefaultDuration = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 15px;

  font-family: 'PF Handbook Pro Regular', sans-serif; /* Added fallback font */
  white-space: nowrap;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-family: 'PF Handbook Pro Regular', sans-serif; /* Added fallback font */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 60%;
  height: 100%;
  padding: 3em;
  justify-content: center;
`;
