// VideoCall.js
import React, { useEffect } from 'react';
import useMe from '../../hooks/useMe';
import VideoChat from '../VideoChat';
import * as S from './styles';
import useTimeOnPage from '../../hooks/useTimeOnPage';
import { usePrescription } from '../../providers/PrescriptionContext';
import Swal from 'sweetalert2';

const VideoCall = ({ id, appointment, handleLeaveQueue }) => {
  const timeOnPage = useTimeOnPage();
  const { hasStartedMeet } = usePrescription();
  const { me: data } = useMe();

  useEffect(() => {
    if(timeOnPage > 120 && hasStartedMeet === false){
      Swal.fire({
        title: "Oops!",
        text: "Você excedeu o tempo máximo na sala. Por favor, saia e entre novamente",
        timer: 5000,
        timerProgressBar: true,
        icon: "info",
        confirmButtonText: "Ok",
      }).then(() => {
        handleLeaveQueue(appointment, true);      
      });
    }
  },[timeOnPage, hasStartedMeet, appointment, handleLeaveQueue]);

  return (
    <S.Container>
      {data?.name && (
        <VideoChat
          name={data.name}
          roomName={id}
          doctorId={data.id}
          appointment={appointment}
        />
      )}
    </S.Container>
  );
};

export default VideoCall;
