// Importações necessárias
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IoLockClosedOutline,
  IoPersonOutline,
  IoShieldCheckmarkOutline,
} from 'react-icons/io5';
import Modal from 'react-modal';
import * as S from './styles';
import useHttpService from '../../hooks/useHttpService';
import Swal from 'sweetalert2';
import { GiCancel, GiTrade } from 'react-icons/gi';
import { v4 as uuid } from 'uuid'
import InputMask from 'react-input-mask';
import { isCaExpired } from '../../rules/isCaExpired';


// Configuração do elemento raiz para o modal
Modal.setAppElement('#root');

let interval = null;

const ProfileCertified = () => {
  const { handleRequest } = useHttpService('/users/safeweb-data')
  const { data, handleRequest: handleGetRequest } = useHttpService('/users/safeweb-data')
  const { handleRequest: handleAuthorize } = useHttpService('/safeweb/authorize-ca')
  const [showForm, setShowForm] = useState(false)

  console.log(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');

  const hasExpired = useMemo(() => isCaExpired(data?.data?.createdAt, data?.data?.expires_in), [data]);

  const handleValidate = useCallback(async (event) => {
    event.preventDefault()
    // setIsModalOpen(true)
    try {
      Swal.fire({
        title: 'Estamos te autenticando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false
      })

      await handleRequest('post', {
        cpf,
        caPassword: password,
        state: uuid()
      })

      await handleAuthorize('post')

      Swal.fire({
        icon: 'info',
        title: 'Solicitação enviada para o seu aplicativo SafeID!',
        text: 'Agora, você receberá uma notificação em seu celular para confirmar a autenticação. Após confirmar, a tela será atualizada automaticamente.',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading()
          interval = setInterval(async () => {
            await handleGetRequest('get')
          }, 5000)
        }
      })

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao autenticar',
        text: error.message
      })
    }

  }, [cpf, password]);


  useEffect(() => {
    handleGetRequest('get')
  }, [handleGetRequest])

  useEffect(() => {
    if (data?.exists) {
      setShowForm(!data?.exists)
      clearInterval(interval)

      localStorage.setItem("@bluemed-dashboard:ca-exists", hasExpired ? false : data?.exists);
      Swal.close()
    }
  }, [data])

  const handleDelete = useCallback(async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar'
      })

      if(!isConfirmed) return;

      Swal.fire({
        title: 'Removendo certificado...',
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false
      })

      await handleRequest('delete')

      Swal.fire({
        icon: 'success',
        title: 'Certificado removido com sucesso!',
        text: 'Você pode adicionar um novo certificado agora.'
      })
      setShowForm(!showForm)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao remover certificado',
        text: error.message
      })
    }
  }, [handleRequest, showForm])

  const alterForm = useCallback(() => {
    if(data?.exists) {
      handleDelete()
    }
  }, [data])

  // useEffect(() => {
  //   Swal.fire({  
  //     title: "Atenção!",  
  //     text: 'Você precisa recadastrar seu certitficado!',
  //   })
  // }, [hasExpired])

  return (
    <S.Container>
      {
        data?.exists && !showForm ? (<center>
          <h2>{hasExpired ? 'Recadastre seu certificado!' : 'Certificado já cadastrado!'}</h2>
          <br />
          <S.ValidateButton type='button' onClick={alterForm}>
            <GiTrade />
            {hasExpired ? 'Recadastrar' : 'Alterar certificado'}
          </S.ValidateButton>
        </center>) : (<>
          <form onSubmit={handleValidate}>
            <S.InputList>
              <S.InputGroup>
                <IoPersonOutline />

                <InputMask
                  mask='99999999999'
                  maskChar=''
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                >
                  {(inputProps) => <S.Input {...inputProps} placeholder='CPF - Apenas números' />}
                </InputMask>
              </S.InputGroup>

              <S.InputGroup>
                <IoLockClosedOutline />
                <S.Input
                  type='password'
                  placeholder='Senha'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </S.InputGroup>
            </S.InputList>

            <S.ValidateButton type='submit'>
              <IoShieldCheckmarkOutline />
              Validar
            </S.ValidateButton>
          </form>
        </>)
      }

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel='QR Code Modal'
        overlayClassName={S.ModalOverlay}
        className={S.ModalContent}
      >
        <QRCode
          value={`CPF: ${cpf}, Password: ${password}`}
        />
        <S.CloseButton onClick={() => setIsModalOpen(false)}>
          Fechar
        </S.CloseButton>
      </Modal>
    </S.Container>
  );
};

export default ProfileCertified;