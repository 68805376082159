import React from "react";
import * as S from "./styles";

const ProductPrescriptions = ({ products, handleProductSelectForPrescription }) => {
  return (
    <S.ProductPrescriptionArea>
      {products.map((product) => {
        const { id, nameWithConcentration, concentration } = product || {};

        return (
          <S.ProductPrescriptionCard
            key={id}
            onClick={() => handleProductSelectForPrescription(product)}
          >
            <S.ProductTitle>{nameWithConcentration}</S.ProductTitle>
            <S.ProductConcentration>
              {concentration}
            </S.ProductConcentration>
  
            <S.ProductSelectButton>+</S.ProductSelectButton>
          </S.ProductPrescriptionCard>
        )
      })}
    </S.ProductPrescriptionArea>
  );
};

export default ProductPrescriptions;