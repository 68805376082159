import { object, ref, string } from "yup";

export const step1Schema = object({
  name: string().required("O Nome e Sobrenome é obrigatório"),
  whatsapp: string()
    .matches(/\([0-9][0-9]\) 9 ([0-9]{4})-([0-9]{4})/g, "Celular Inválido")
    .required("O número e obrigatório"),
  email: string()
    .email("O e mail não e valido")
    .required("O Email é obrigatorio"),
  password: string()
    .min(8, "O tamanho mínimo tem que ser 8")
    .required("A senha é obrigatório"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "As senhas não coincidem")
    .required("Você precisa confirmar a senha"),
});
