import React from 'react';
import { BiSolidCity } from 'react-icons/bi';
import { BsEnvelope } from 'react-icons/bs';
import { RiUserSettingsLine } from 'react-icons/ri';

import { PiPhoneOutgoing } from 'react-icons/pi';

import { FiUser } from 'react-icons/fi';
import {
  LiaIdCard,
  LiaMapMarkedSolid,
  LiaMapMarkerAltSolid,
} from 'react-icons/lia';

import * as S from './styles';

const ProfileSettings = ({
  person = {},
  email = '',
  cpf = '',
  address = '',
  zipcode = '',
  city = '',
  phone = '',
}) => {
  return (
    <S.Container>
      <S.Title>
        <RiUserSettingsLine />
        Informações Pessoais
      </S.Title>
      <S.InfoContainer>
        <S.InfoItem>
          <FiUser />
          {person.name || 'Nome não fornecido'}
        </S.InfoItem>
        <S.InfoItem>
          <BsEnvelope />
          {email}
        </S.InfoItem>
        <S.InfoItem>
          <LiaIdCard />
          {cpf}
        </S.InfoItem>
        <S.InfoItem>
          <LiaMapMarkedSolid />
          {address}
        </S.InfoItem>
        <S.InfoItem>
          <LiaMapMarkerAltSolid />
          {zipcode}
        </S.InfoItem>
        <S.InfoItem>
          <BiSolidCity />
          {city}
        </S.InfoItem>
        <S.InfoItem>
          <PiPhoneOutgoing />
          {phone}
        </S.InfoItem>
        {/* Add more fields as necessary */}
      </S.InfoContainer>
    </S.Container>
  );
};

export default ProfileSettings;
