// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  position: relative;
  flex-direction: column;
  background: linear-gradient(117deg, #fff -2.76%, #f1f1f1 98.46%);
  border-radius: 24px;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 3em;
  gap: 1em;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MenuPanel = styled.div`
  display: flex;
  align-items: center;
`;

export const TrashIconContainer = styled.div`
  position: absolute;
  top: 0.4em;
  right: 0.4em;
  display: flex;

  padding: 15px;
  font-size: 2.2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--Grey-Grey-30, #eaeef4);
  background: var(--Primary-White, #fff);
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileIcon = styled.img`
  /* Styles for profile icon */
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusBadge = styled.span`
  background-color: ${(props) => (props.active ? '#2DD2B4' : '#FF0000')};
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 9px;
  margin-bottom: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 6.13px */
  letter-spacing: 0.429px;
`;

export const Name = styled.h1`
  color: #7d7d7d;
  text-align: left;
  font-size: 24.443px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24.443px */
`;

export const CRM = styled.span`
  color: #7d7d7d;
  text-align: left;
  font-family: Helvetica;
  font-size: 15.041px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 15.041px */
`;

export const Icon = styled.img`
  margin-right: 10px;
  /* Additional styles */
`;

export const IconName = styled.img`
  width: 24px;
  height: auto;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 100%;
  display: flex;
  gap: 0.4em;
  align-items: center;
  margin-bottom: 0.2em;
  svg {
    font-size: 21px;
  }
`;
