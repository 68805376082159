// styles.js
import styled, { css } from 'styled-components';
export const TitleHeading = styled.h2`
  color: #fff;
  font-size: 18px;
  line-height: 1.1em;
  font-weight: 500;
  width: 90%;
  text-align: center;
  margin-top: 1em;
`;

export const Container = styled.div`
  border-radius: 22px 22px 0 0;
  margin-top: 1em;
  gap: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(5, 60, 78);
  background: linear-gradient(
    90deg,
    rgba(5, 60, 78, 1) 0%,
    rgba(45, 145, 213, 1) 100%
  );
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
`;
export const IconName = styled.img`
  width: 40px;
  height: auto;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const CompanyLogo = styled.img`
  height: 20px;
`;
export const Content = styled.div`
  width: 100%;
`;

export const InlineTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const Title = styled.div`
  color: #93aeba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  gap: 0.4em;
  align-items: center;
  width: 100%;
  display: flex;

  svg {
    font-size: 24px;
  }
`;
export const DocumentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-radius: 12px;
  background-color: #f9f9f9;
  margin-bottom: 8px;
  transition: transform 0.2s;
  width: 100%;

  &:hover {
    transform: translateY(-2px);
  }
`;

export const DocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const DocumentName = styled.span`
  font-size: 16px;
  color: #333;
  font-weight: 500;
`;

export const DocumentDate = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  gap: 4px;
`;

export const DownloadButton = styled.button`
  background: #f5f5f5;
  color: #121212;
  border: none;
  padding: 8px 16px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #5a6268;
    color: #f2f2f2;
  }

  svg {
    font-size: 14px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0em;
`;

export const ViewButton = styled.button`
  background: #f2f2f2;
  color: #121212;
  border: none;
  padding: 8px 16px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;

  &:hover {
    color: #f2f2f2;
    background-color: #121212;
  }

  svg {
    font-size: 14px;
  }
`;

export const SignatureContainer = styled.div`
  padding-bottom: 00px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignatureActions = styled.div`
  display: flex;
  margin: 1em 0 2em;
  gap: 10px;
  width: 80%;
  justify-content: center;
  align-items: center;
`;

export const Signature = styled.div`
  background-color: #fff;
  border-radius: 15px;
  border: 1px dashed #999;
  margin-top: 1em;
  width: 400px;
  height: 130px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 0.1rem;

    background-color: #000;
  }

  .sigCanvas {
    width: 100% !important;
  }

  ${({ isEnabled }) =>
    !isEnabled &&
    css`
      opacity: 0.3;
    `}
`;

export const SaveButton = styled.button`
  background-color: #00abd1;
  border: none;
  border-radius: 15px;
  color: #fff;
  padding: 10px 20px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border: none;
  width: 100%;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0260a9;
  }
`;

export const ClearButton = styled.button`
background-color: #00abd1;
border: none;
border-radius: 15px;
color: #fff;
padding: 10px 20px;
height: 50px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
gap: 1em;
border: none;
width: 100%;
transition: background-color 0.2s;

&:hover {
  background-color: #0260a9;
}
`;


export const CancelButton = styled.button`
  background-color: #f44336;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border: none;
  height: 50px;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  &:hover {
    background-color: #d32f2f;
  }
`;

export const ButtonEdit = styled.button`
  background-color: #00abd1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border: none;
  border-radius: 15px;
  color: #fff;
  padding: 10px 20px;
  height: 50px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0260a9;
  }

  svg {
    font-size: 20px;
  }
`;
