import styled from 'styled-components';

export const InputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
  font-size: 15px;
  padding: 0px;
  border-radius: 12px;
  height: auto;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? 'red' : '#ccc')};
  margin-bottom: 0px;
  overflow: hidden;
`;

export const Icon = styled.div`
  color: #ccc;
  margin-left: 10px;
  margin-right: 10px;
  svg {
    font-size: 18px; // Adjust based on the desired size of the icons
  }
`;
export const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  padding-left: 0px;
  min-height: 50px;
  font-size: 15px;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

export const Error = styled.span`
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  text-align: center;
`;
