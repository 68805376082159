// styles.ts
import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  width: fit-content;
  font-family: "PF Handbook Pro Regular", sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%);
  border: none;
  color: #ffff;

  &:hover {
    box-shadow: none;
    transition: all 0.8s ease;
    background: #4fbce6;
    color: #ffff;
  }
`;
