// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 32px;
  padding: 2em 2em;
  position: relative;
  background: var(
    --BlueNews,
    linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%)
  );
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  width: 180px;
  height: auto;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Menu = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const MenuItem = styled.button`
  border: none;
  color: #ffffff90;
  gap: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 21px;
  min-height: 50px;

  margin: 5px 0;
  font-size: 14px;
  text-align: left;
  transition: all 0.3s ease;
  background: linear-gradient(180deg, #28a6cb10 0%, #1f90b430 100%);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 100px;
  background-color: transparent;
  &:hover {
    background: linear-gradient(180deg, #fff 0%, #ffffff80 100%);
    box-shadow: 5px 5px 28px #00000030;
    color: #1f90b4;
    font-size: 0;

    svg {
      color: #1f90b4;
      font-size: 34px;
    }

    p {
      display: none;
    }
  }

  svg {
    color: #ffffff90;
    font-size: 1.8em;
  }
`;

export const LogoutButton = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  color: #ffffff70;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6em;
  cursor: pointer;

  border-radius: 0 0 21px 21px;

  &:hover {
    transition: all 0.3s ease;
    background: rgb(48, 48, 48);
    background: linear-gradient(
      0deg,
      rgba(48, 48, 48, 0.1601234243697479) 0%,
      rgba(54, 54, 54, 0) 100%
    );
    width: 100%;
    border-radius: 0 0 21px 21px;
  }
`;

export const Logo = styled.img`
  width: 120px;
  margin: 2em 0;
  z-index: 0;
  height: auto;
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
`;

export const Copy = styled.h3`
  color: #ffffff80;
  margin: 0;
  border-bottom: 1px solid #ffffff50;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 100%;
  padding-bottom: 1em;
`;

export const RowBottom = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0;
`;

export const Logout = styled.img`
  width: 30px;
  opacity: 0.4;
  margin: 2em 0;
  z-index: 1;
  height: auto;
`;
