import { object, string } from "yup";

export const step2Schema = object({
  cpf: string()
    .matches(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)/g, "Insira um CPF Válido")
    .required("O CPF é obrigatório"),
  crm: string().required("O CRM é obrigatório"),
  rg: string().required("O RG é obrigatorio"),
  dateOfBirth: string()
    .matches(/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/, " ")
    .required("A data de nascimento é obrigatório"),
});
