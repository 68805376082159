import React, { useEffect, useState } from 'react';
import Input from '../../../Input';
import * as S from './styles';

const weekDayTranslation = {
  segunda: 'Segunda',
  terca: 'Terça',
  quarta: 'Quarta',
  quinta: 'Quinta',
  sexta: 'Sexta',
  sabado: 'Sábado',
  domingo: 'Domingo',
};

const WeekSchedule = ({ day, index, methods }) => {
  const [working, setWorking] = useState(false); // Inicializado como true por padrão
  const { register } = methods;

  const watchStart = methods.watch(`weekdays.${day}.startHour`);
  const watchInterval = methods.watch(`weekdays.${day}.intervalHour`);
  const watchReturn = methods.watch(`weekdays.${day}.returnHour`);
  const watchFinish = methods.watch(`weekdays.${day}.finishHour`);
  const watchWorking = methods.watch(`weekdays.${day}.working`);

  const handleSwitchChange = () => {
    const newWorking = !working; // Inverte o estado atual
    setWorking(newWorking); // Atualiza o estado local
    methods.setValue(`weekdays.${day}.working`, newWorking); // Atualiza o valor no formulário
  };
  useEffect(() => {
    if (!working) {
      methods.setValue(`weekdays.${day}.startHour`, '');
      methods.setValue(`weekdays.${day}.intervalHour`, '');
      methods.setValue(`weekdays.${day}.returnHour`, '');
      methods.setValue(`weekdays.${day}.finishHour`, '');
    }
  }, [working]);

  useEffect(() => {
    if (watchWorking !== undefined) {
      setWorking(watchWorking);
    }
  }, [watchWorking]);

  return (
    <S.ContainerModal>
      <S.Weekday key={index}>
        <S.InlineContent>
          {weekDayTranslation[day]}
          <S.SwitchButton onChange={handleSwitchChange}>
            <S.InputCheckbox type='checkbox' checked={working} />
            <S.Slider />
          </S.SwitchButton>
        </S.InlineContent>
        <Input
          {...register(`weekdays.${day}.startHour`)}
          placeholder='Entrada'
          mask={'99:99'}
          name={`weekdays.${day}.startHour`}
          value={watchStart}
          icon='startHour'
          style={{
            opacity: working ? 1 : 0.5,
            pointerEvents: working ? 'all' : 'none',
          }}
        />
        <Input
          {...register(`weekdays.${day}.intervalHour`)}
          placeholder='Intervalo'
          mask={'99:99'}
          name={`weekdays.${day}.intervalHour`}
          value={watchInterval}
          icon='pause'
          style={{
            opacity: working ? 1 : 0.5,
            pointerEvents: working ? 'all' : 'none',
          }}
        />
        <Input
          {...register(`weekdays.${day}.returnHour`)}
          placeholder='Retorno'
          mask={'99:99'}
          name={`weekdays.${day}.returnHour`}
          value={watchReturn}
          icon='returnHour'
          style={{
            opacity: working ? 1 : 0.5,
            pointerEvents: working ? 'all' : 'none',
          }}
        />
        <Input
          {...register(`weekdays.${day}.finishHour`)}
          placeholder='Saída'
          mask={'99:99'}
          name={`weekdays.${day}.finishHour`}
          value={watchFinish}
          icon='clock'
          style={{
            opacity: working ? 1 : 0.5,
            pointerEvents: working ? 'all' : 'none',
          }}
        />
      </S.Weekday>
    </S.ContainerModal>
  );
};

export default WeekSchedule;
