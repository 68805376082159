import React from "react";
import { usePrescription } from "../../providers/PrescriptionContext";
import * as S from "./styles";

const ButtonPatientWhitoutPrescription = ({ handleLeaveQueue, appointment }) => {
  const { hasStartedMeet, hasPrescripted } = usePrescription();

  if (hasStartedMeet && !hasPrescripted) {
    return (
      <S.Button onClick={() => handleLeaveQueue(appointment, false, 'canceled')}>
        Paciente não terá prescrição
      </S.Button>
    );
  }
};

export default ButtonPatientWhitoutPrescription;
