import styled from 'styled-components';
const envelopeIcon = '/icons/crm.svg';
const lockIcon = '/icons/lock.svg';
const userIcon = '/icons/profile.svg';
const phoneIcon = '/icons/phone.svg';
const idCardIcon = '/icons/id-card.svg';
const stethoscopeIcon = '/icons/stethoscope.svg';
const mapMarkerIcon = '/icons/map-marker.svg';
const homeIcon = '/icons/home.svg';
const postalCodeIcon = '/icons/postal-code.svg';

const icons = {
  envelope: envelopeIcon,
  lock: lockIcon,
  user: userIcon,
  phone: phoneIcon,
  idCard: idCardIcon,
  stethoscope: stethoscopeIcon,
  mapMarker: mapMarkerIcon,
  home: homeIcon,
  postalCode: postalCodeIcon,
};

export const Header = styled.div`
  margin-top: 20px;
  flex-direction: column;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 2em;

  @media screen and (min-width: 1024px) {
  }
`;

export const Body = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  background-image: url('/images/medical-staff-bg.png');
  background-size: cover;
  background-size: 110% 110%;
  background-position: center;
  background-repeat: no-repeat;

  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeadingBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  margin-top: 4em;
  z-index: 1;
  align-items: flex-start;
`;

export const Title = styled.h2`
  color: #fff;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
`;

export const SubTitle = styled.h5`
  color: #d6d6d6;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 25.849px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2em;
`;

export const InputWrapper = styled.div`
  background: #e9f9ff;
  padding: 0.5em 2em;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  position: relative;
  margin-bottom: 10px; // Add space between input fields

  &::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url(${(props) => icons[props.icon] || icons.user});
    background-size: contain;
    background-repeat: no-repeat;
  }

  input {
    border: none;
    background: transparent;
    width: calc(100% - 40px); // Adjust width to account for icon space
    height: 100%;
    margin-left: 40px; // Make room for the icon
    &:focus {
      outline: none;
    }
  }

  // Remove autofill background color
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  // For Firefox
  &:-moz-autofill,
  &:-moz-autofill:hover,
  &:-moz-autofill:focus,
  &:-moz-autofill:active {
    filter: none;
    background-color: white !important;
  }
`;
export const Input = styled.div`
  background: #e9f9ff;
  padding: 0.5em 2em;
  border-radius: 17px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  position: relative; /* Adicionado para posicionar o ícone */

  input {
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    padding-left: 2em; /* Espaço para o ícone */
    &:focus {
      outline: none;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 10px; /* Ajuste conforme necessário */
    top: 50%;
    transform: translateY(-50%);
    background: url(${(props) => icons[props.icon] || icons.user});
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const SendButton = styled.button`
  display: flex;
  width: 100%;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;

  height: 60px;
  padding: 19px 33px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border: none;
  color: #fff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #29b5d7;
  }
`;

export const Logo = styled.img`
  width: 230px;
  z-index: 1;
  height: auto;
`;

export const LogoBackgroundAvatar = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  right: 0px;
`;

export const Forms = styled.div`
  border-radius: 25px;
  padding: 3em;
  opacity: 1;

  backdrop-filter: blur(32px);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
  max-width: 460px;
  align-content: stretch;

  @media screen and (max-width: 600px) {
    max-width: 90%;
  }
`;

export const MedicalStaff = styled.div`
  font-size: 20px;
  color: #fff;
  z-index: 1;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  line-height: 1.5;
`;

export const InputTitle = styled.h2`
  color: #2d7db9;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 21px;
  margin-top: 1em;
  margin-bottom: 0.3rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
  gap: 1em;
  justify-content: space-between;
`;
