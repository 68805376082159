import React from 'react';
import * as S from './styles';
const FeaturedIcon = '/assets/icons/notes.svg';
const MedicalListing = () => {
  // Mock data, replace with actual data as needed
  const appointments = [
    {
      name: 'Nome do médico',
      condition: 'Especialidade 01',
      insomnia: 'Especialidade 02',
      date: '13 de dezembro às 13:30',
      status: 'Aprovar ou recusar',
    },
    // Add more appointments as needed
  ];

  return (
    <S.Container>
      <S.Title>
        <S.Icon src={FeaturedIcon} alt='teste' />
        Médicos
      </S.Title>

      {appointments.map((appointment, index) => (
        <S.Appointment key={index}>
          <S.PatientInfo>
            <S.PatientAvatar
              src='/images/account/profile-avatar.jpg'
              alt={`${appointment.name} avatar`}
            />
            <S.PatientName>{appointment.name}</S.PatientName>
            <S.Condition>{appointment.condition}</S.Condition>
            <S.Insomnia>{appointment.insomnia}</S.Insomnia>
          </S.PatientInfo>
          <S.AppointmentDetails>
            <S.Date>{appointment.date}</S.Date>
            <S.Status isCancelled={appointment.status === 'Cancelada'}>
              {appointment.status}
            </S.Status>
          </S.AppointmentDetails>
        </S.Appointment>
      ))}
    </S.Container>
  );
};

export default MedicalListing;
