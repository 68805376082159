// styles.ts
import styled from 'styled-components';

export const ProductPrescriptionArea = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0.8em;
`;

export const ProductPrescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1em;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
  &:hover {
    transform: translateY(-5px);
  }
  // Additional styling
`;

export const ProductTitle = styled.h3`
  // Title styling
`;

export const ProductConcentration = styled.p`
  // Concentration styling
`;

export const ProductSelectButton = styled.button`
  background: #e7ecef;
  border: none;
  border-radius: 120px;
  font-size: 18px;
  margin-top: 1em;
  width: 100%;
  // Additional styling
  &:hover {
    background-color: #29b5d7;
    color: #fff;
  }
`;
