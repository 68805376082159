// styles.js
import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  min-height: auto;
  box-sizing: border-box;
  padding: 1em;
  background: #ededed;
  gap: 1em;
  height: 100vh;
`;
export const ContentGridRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  gap: 1em;
`;
export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;
export const Content = styled.section`
  display: flex;
  width: 100%;
  gap: 1.2em;
  align-content: stretch;
  align-items: stretch;
  @media (min-width: 768px) {
    // styles for desktop
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const PrescriptionButton = styled.button`
  width: 100%;
  border-radius: 120px;
  background: var(--BlueBg, linear-gradient(180deg, #00abd1 0%, #0260a9 100%));
  box-shadow: 0px 2px 15px 0px rgba(44, 123, 183, 0.49);
  display: flex;
  border: none;
  color: #fff;
  height: 64px;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

export const BodyContent = styled.div`
  display: flex;
  gap: 1em;
`;

export const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;
  @media (min-width: 768px) {
    // styles for desktop
    flex-direction: column;
    flex: 1;
    gap: 1em;
  }
`;

export const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;
  @media (min-width: 768px) {
    // styles for desktop
    flex-direction: column;
    flex: 1;
    gap: 1em;
  }
`;

export const Button = styled.button`
  display: flex;
  width: fit-content;
  font-family: 'PF Handbook Pro Regular', sans-serif;
  font-size: 21px;
  cursor: pointer;
  gap: 0.8em;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(180deg, #29b5d7 0%, #2d7eb9 100%);
  border: none;
  color: #ffff;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #29b5d7;
  }
`;
