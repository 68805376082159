import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../../../../components/Input';
import { httpService } from '../../../../services/axios';
import { debounce } from '../../../../services/utils/debounce';
import * as S from '../../styles';
import { step3Schema } from './schema';
const Step3 = ({ handleNext }) => {
  const methods = useForm({
    resolver: yupResolver(step3Schema),
  });

  const errors = methods.formState.errors;

  const submit = (values) => {
    console.log('values:::', values);
    handleNext('step3', values);
  };

  const VIACEP = (cep) =>
    `https://viacep.com.br/ws/${cep.replace('-', '')}/json/`;

  const handleGetCep = async (e) => {
    try {
      const { data } = await httpService.get(VIACEP(e.target.value));
      console.log(data);
      methods.setValue('street', data.logradouro);
      methods.setValue('neighborhood', data.bairro);
      methods.setValue('city', data.localidade);
      methods.setValue('state', data.uf);
      methods.setValue('cep', data.cep);
    } catch (error) {
      return {};
    }
  };

  const debouncedHandleGetCep = debounce(handleGetCep, 1000);

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(submit)}>
        <Input
          icon='postalCode'
          name='cep'
          error={errors?.cep?.message}
          placeholder='Insira seu CEP'
          onChange={(e) => debouncedHandleGetCep(e)}
        />

        <Input
          icon='home'
          name='street'
          error={errors?.street?.message}
          placeholder='Insira o nome da sua rua'
        />

        <Input
          icon='neighborhood'
          name='neighborhood'
          error={errors?.neighborhood?.message}
          placeholder='Insira o nome do seu bairro'
        />

        <Input
          icon='number'
          name='number'
          error={errors?.number?.message}
          placeholder='Insira o numero da sua casa'
        />

        <Input
          icon='mapMarker'
          name='state'
          error={errors?.state?.message}
          placeholder='Insira o estado em que você mora'
        />

        <Input
          icon='city'
          name='city'
          error={errors?.city?.message}
          placeholder='Insira a cidade em que você mora'
        />

        <S.SendButton>Próximo</S.SendButton>
      </S.Form>
    </FormProvider>
  );
};

export default Step3;
